@use "../global" as g;

.p-case {

  .filtering-sec {
    padding: 45px 0;
    background: g.$gray;

    .inner {
      .block {
        margin-bottom: 35px;

        &:last-child {
          margin-bottom: 0;
        }

        dl {
          dt {
            font-weight: 700;
            font-size: 1.6rem;
            margin-bottom: 15px;
          }

          dd {
            ul {
              display: flex;
              flex-wrap: wrap;
              gap: percentage(14px / 1100px);

              @include g.mq-down(lg) {
                justify-content: space-between;
                gap: 1%;
              }

              li {
                width: percentage(171px / 1100px);

                @include g.mq-down(lg) {
                  width: 48%;
                  margin-bottom: 4%;
                }

                button {
                  display: block;
                  width: 100%;
                  padding: 10px 0;
                  text-align: center;
                  color: g.$orange;
                  border: 1px solid g.$orange;
                  border-radius: 50px;

                  &.active {
                    background: g.$orange;
                    color: #fff;
                  }
                }
              }
            }

            .tags {
              @include g.mq-down(lg) {
                justify-content: flex-start;
                gap: 0;
              }

              li {
                width: inherit;
                display: inline-block;
                color: g.$orange;
                margin-right: 10px;
                line-height: 2;
                cursor: pointer;

                @include g.mq-down(lg) {
                  margin-right: 20px;
                }

                label {
                  &::before {
                    content: "# ";
                  }
                }

                input[type="checkbox"] {
                  display: none;
                  background-color: transparent;
                  border: none;
                  cursor: pointer;
                  outline: none;
                  padding: 0 0 2px;
                  appearance: none;

                  &:checked+label {
                    font-weight: 700;
                    border-bottom: 1px solid g.$orange;
                  }
                }


              }
            }
          }
        }
      }
    }
  }

  .content-sec {
    padding: 80px 0 60px;


    @include g.mq-down(lg) {
      padding: 60px 0;
    }

    .inner {
      .midashi {
        text-align: center;
        margin-bottom: 30px;

        .en {
          font-size: 4rem;
          font-weight: 700;

          @include g.mq-down(lg) {
            font-size: 3rem;
          }

          span {
            font-weight: 200;
          }
        }
      }

      .list {
        margin-left: - calc(percentage(30px / 1100px) / 2);
        width: calc(100% + percentage(30px / 1100px));

        @include g.mq-down(lg) {
          width: 100%;
          margin: 0 10px;
        }

        .item {
          width: percentage(250px / 1100px);
          max-width: 250px;
          margin: 0 calc(percentage(30px / 1100px) / 2) 20px;
          box-sizing: content-box;

          @include g.mq-down(lg) {
            width: calc(100% - 20px);
            max-width: calc(100% - 20px);
            margin: 0 auto 40px;
          }

          a {
            display: block;
            @include g.image-link_zoom();
          }

          figure {
            width: 100%;
            height: 100%;
            max-height: 250px;
            @include g.imgFit();

            @include g.mq-down(lg) {
              height: g.spvw(315);
              max-height: g.spvw(315);
            }
          }

          .caption {

            .title {
              font-weight: 700;
              margin-bottom: 10px;
            }

            .cat {
              margin-bottom: 5px;

              @include g.mq-down(lg) {
                font-size: 1.2rem;
              }

              span {
                display: inline-block;
                color: g.$orange;
                border: 1px solid g.$orange;
                padding: 3px 10px;
                border-radius: 50px;
                margin: 0 4px 4px 0;
              }
            }

            .tag {
              @include g.mq-down(lg) {
                font-size: 1.2rem;
              }

              span {

                color: g.$orange;

                &::before {
                  content: "# ";
                }

                &::after {
                  content: " | ";
                  display: inline-block;
                  padding: 0 5px;
                }

                &:last-child {
                  &::after {
                    content: "";
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
