@use "../global" as g;

.p-news {

  .content-sec {
    background: g.$gray;
    padding: 80px 0 120px;


    @include g.mq-down(lg) {
      padding: 60px 0;
    }

    .inner {
      .box {
        display: flex;
        padding-left: calc(50vw - 1100px / 2);

        @include g.mq-down(lg) {
          display: block;

        }
      }

      .midashi {
        text-align: center;
        margin-bottom: 30px;

        .en {
          font-size: 4rem;
          font-weight: 700;

          @include g.mq-down(lg) {
            font-size: 3rem;
          }

          span {
            font-weight: 200;
          }
        }
      }

      .main-body {
        width: 816px;

        @include g.mq-down(lg) {
          width: calc(100% - 40px);
          margin: 0 auto;

        }

        .list {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          gap: percentage(33px / 816px);

          @include g.mq-down(lg) {
            display: block;
            width: 100%;
            padding: 0 30px;

          }

          .item {
            width: percentage(250px / 816px);
            max-width: 250px;
            box-sizing: content-box;
            margin-bottom: 40px;

            @include g.mq-down(lg) {
              width: 100%;
              max-width: 100%;
              box-sizing: border-box;
            }

            a {
              display: block;
              @include g.image-link_zoom();
            }

            figure {
              width: 100%;
              height: 100%;
              max-height: 250px;
              margin-bottom: 15px;
              @include g.imgFit();

              @include g.mq-down(lg) {
                height: g.spvw(315);
                max-height: g.spvw(315);
              }

              .deco {
                @include g.mq-down(lg) {
                  display: none;
                }
              }
            }


          }
        }
      }

      aside {
        width: calc(100% - 816px);
        padding-left: 108px;

        @include g.mq-down(xxl) {
          padding-left: 54px;
        }

        @include g.mq-down(lg) {
          width: 100%;
          padding: 0 0 0 30px;
        }

      }
    }
  }


}
