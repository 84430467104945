@use "../global" as g;

.p-service-single {
  .mv-sec {
    margin-bottom: 90px;

    @include g.mq-down(lg) {
      margin-bottom: -40px;
    }


    .inner {
      padding: 0 33px;

      @include g.mq-down(lg) {
        padding: 0;
      }

      .title {
        @include g.mq-down(lg) {
          top: 20%;
        }

      }
    }
  }

  .outline-sec {
    margin-bottom: 120px;

    @include g.mq-down(lg) {
      margin-bottom: 80px;
    }

    .inner {
      .box {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;

        @include g.mq-down(lg) {
          display: block;
        }

        .photo {
          width: 436px;
          position: relative;

          @include g.mq-down(lg) {
            width: 100%;
            margin-bottom: 40px;
          }

          figure {
            position: absolute;
            bottom: calc(100% - 250px);
            right: -50px;
            width: 436px;
            height: 631px;
            @include g.imgFit();

            @include g.mq-down(lg) {
              position: static;
              width: 100%;
              height: g.spvw(456);
            }
          }
        }

        .text {
          width: calc(100% - 436px);
          padding-right: 60px;

          @include g.mq-down(lg) {
            width: 100%;
            padding-right: 0;
          }

          .midashi {
            font-size: 2rem;
            font-weight: 700;
            margin-bottom: 20px;

            @include g.mq-down(lg) {
              margin-bottom: 10px;
            }
          }

          .catch {
            font-size: 2rem;
            font-weight: 700;
            margin-bottom: 20px;
          }

          .read {
            font-weight: 700;
            line-height: 2;
          }
        }
      }
    }
  }

  .casestudy-sec {
    padding: 75px 0 85px;
    background: g.$gray;

    .inner {
      .midashi {
        text-align: center;
        margin-bottom: 30px;

        .en {
          font-size: 2rem;
          font-weight: 700;
          display: block;

          @include g.mq-down(md) {
            font-size: 1.8rem;
          }
        }

        .ja {
          font-weight: 700;
          display: block;
        }
      }

      .slides {
        margin-left: -16.5px;
        width: calc(100% + 33px);

        @include g.mq-down(lg) {
          width: calc(100% + 40px);
          margin-left: -20px;
        }

        .slide {
          box-sizing: content-box;
          width: 250px;

          @include g.mq-down(lg) {
            width: 100%;
          }

          a {
            display: block;
            margin: 0 16.5px;

            @include g.mq-up(lg) {
              @include g.image-link_zoom();
            }


            @include g.mq-down(lg) {
              margin: 0;
            }

            figure {
              width: 100%;
              height: g.pcvw(250);
              max-height: 250px;
              position: relative;
              @include g.imgFit();

              @include g.mq-down(lg) {
                width: 100%;
                padding: 0 15px;
                height: auto;
                max-height: none;

              }


              .btn {
                position: absolute;
                bottom: 15px;
                right: 15px;

                @include g.mq-down(lg) {
                  display: none;
                }
              }
            }

            .caption {
              .title {
                text-align: center;
                font-weight: 700;
                margin-bottom: 10px;
              }

              .cat {
                text-align: center;

                span {
                  display: inline-block;
                  color: g.$orange;
                  border: 1px solid g.$orange;
                  border-radius: 50px;
                  padding: 3px 10px;
                }
              }
            }
          }
        }

      }
    }
  }

  .groupservicesite-sec {
    margin-bottom: 60px;

    @include g.mq-down(lg) {
      margin-bottom: 70px;
    }

    .midashi {
      text-align: center;
      margin-bottom: 40px;

      .en {
        font-size: 2rem;
        font-weight: 700;
        display: block;
        border-bottom: 1px solid g.$orange-thin;
        padding-bottom: 7px;
        margin-bottom: 10px;

        @include g.mq-down(lg) {
          font-size: 1.8rem;
          letter-spacing: 1px;
        }
      }

      .ja {
        font-weight: 700;
        display: block;
      }
    }

    .inner {
      .list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include g.mq-down(lg) {
          display: block;
        }

        .item {
          width: 48.5%;
          margin-bottom: 40px;

          @include g.mq-down(lg) {
            width: 100%;
            margin-bottom: 40px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          a {
            display: block;
            @include g.image-link_zoom();
          }

          figure {
            width: 100%;
            height: 212px;
            @include g.imgFit();

            @include g.mq-down(lg) {
              height: g.spvw(135);
            }
          }

          .caption {
            @include g.mq-down(lg) {
              position: relative;
            }

            .en {
              font-size: 1rem;
              letter-spacing: 1px;
              color: g.$orange;
            }

            .ja {
              font-size: 1.3rem;
              font-weight: 700;
              justify-content: flex-start;

              @include g.mq-down(lg) {
                padding-right: 0;
                display: block;
              }

              .icon {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }

  .ourfield-sec {
    background: g.$gray;
    padding: 80px 0;

    @include g.mq-down(lg) {
      padding: 60px 0;
    }

    .inner {
      .midashi {
        text-align: center;
        margin-bottom: 30px;

        .en {
          font-size: 3rem;
          font-weight: 100;
          letter-spacing: 2px;

          span {
            font-weight: 700;
          }
        }
      }

      .list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 2%;

        @include g.mq-down(lg) {
          display: block;
        }

        .item {
          width: 18%;

          @include g.mq-down(lg) {
            width: 100%;
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          a {
            font-size: 1.6rem;
            letter-spacing: 2px;
            display: block;
            color: g.$orange;
            border: 1px solid g.$orange;
            border-radius: 50px;
            text-align: center;
            padding: 3px 10px;

            @include g.mq-down(lg) {
              padding: 10px 0;
            }

            &:hover {
              background: g.$orange;
              color: #fff;
            }
          }
        }
      }
    }
  }

  footer {
    .inquiry-sec {
      background: linear-gradient(180deg, #fff 0%, #fff calc(50% - 50px), g.$gray calc(50% - 50px), g.$gray 100%);
    }
  }
}
