@use "../global" as g;

.p-group {
  .pagehead-sec {
    margin-bottom: 90px;

    @include g.mq-down(lg) {
      margin-bottom: 40px;
    }
  }

  .mv-sec {
    .inner {
      padding-right: 33px;
      padding-left: calc(50vw - 1100px / 2);

      @include g.mq-down(lg) {
        padding: 0 30px;
      }


      figure {
        width: 100%;
        height: 507px;
        @include g.imgFit();

        @include g.mq-down(lg) {
          height: g.spvw(315);
        }
      }
    }
  }

  .outline-sec {
    margin: 50px auto 100px;

    @include g.mq-down(lg) {
      margin: 50px auto 80px;
    }

    .inner {
      max-width: 1000px;
      width: calc(100% - 60px);
      margin: 0 auto;
    }

    .group-name {
      font-size: 2.5rem;
      font-weight: 700;
      margin-bottom: 20px;
    }

    .catch {
      font-size: 2rem;
      font-weight: 700;
      letter-spacing: 1px;
      margin-bottom: 15px;

      @include g.mq-down(lg) {
        margin-bottom: 20px;
      }
    }

    .read {
      max-width: 700px;
      margin-bottom: 30px;
      font-weight: 700;
      line-height: 2;

      @include g.mq-down(lg) {
        margin-bottom: 40px;
      }
    }

    .business-list {
      .catch {
        font-weight: 700;
        margin-bottom: 15px;

        @include g.mq-down(lg) {
          margin-bottom: 10px;
        }
      }

      .list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 40px;

        @include g.mq-down(lg) {
          display: block;
        }

        .item {
          width: 31%;

          @include g.mq-down(lg) {
            width: 100%;
            margin-bottom: 40px;
          }

          .text {
            margin-top: 10px;

            .ja {
              font-size: 1.6rem;
              font-weight: 700;
              letter-spacing: 1px;
            }
          }
        }
      }

      .caption {
        display: inline-block;

        .ja {
          font-weight: 700;

          a {
            .line {
              padding-bottom: 5px;
              border-bottom: solid 1px #EF7D20;
              margin-right: 5px;
            }

            ;

            &:hover {
              .line {
                border-bottom: none;
              }
            }
          }

        }
      }
    }
  }

  .otherservice-sec {
    background: #F5F3EF;
    padding: 100px 0;

    @include g.mq-down(lg) {
      padding: 80px 0;
    }

    .midashi {
      margin-bottom: 35px;

      .en {
        display: block;
        font-size: 2rem;
        font-weight: 700;
        letter-spacing: 1px;
        text-align: center;

      }

      .ja {
        display: block;
        font-weight: 500;
        text-align: center;
      }
    }

    .list {
      display: flex;
      justify-content: space-between;

      @include g.mq-down(lg) {
        display: block;
      }

      .item {
        width: 31%;
        position: relative;

        @include g.mq-down(lg) {
          width: 100%;
          margin-bottom: 20px;
        }

        .btn {
          .deco {
            @include g.mq-down(lg) {
              bottom: 30px;
            }

          }
        }

        .caption {
          text-align: center;
          margin-top: 15px;

          @include g.mq-down(lg) {
            position: absolute;
            bottom: 30px;
            left: 0;
            right: 0;
            margin: auto;
          }

          .en {
            font-size: 1.2rem;
            color: g.$orange;
            margin-bottom: 0px;
            letter-spacing: 1px;

            @include g.mq-down(lg) {
              color: #fff;
            }

          }

          .ja {
            font-size: 1.8rem;
            font-weight: 700;
            letter-spacing: 1px;

            @include g.mq-down(lg) {
              color: #fff;
            }
          }
        }
      }

    }
  }
}
