@use "../global" as g;

.p-news-single {
  .news-post {
    .title {
      font-size: 2rem;
      font-weight: 700;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid g.$orange-thin;
    }

    .data {
      display: flex;
      margin-bottom: 40px;

      .cat {
        margin-bottom: 5px;
        margin-right: 15px;

        @include g.mq-down(lg) {
          font-size: 1.2rem;
        }

        span {
          display: inline-block;
          color: g.$orange;
          border: 1px solid g.$orange;
          padding: 1px 10px;
          border-radius: 50px;
          margin: 0 4px 4px 0;
          font-size: 1.2rem;
        }
      }

      .pub-date {
        color: g.$orange;
      }
    }

    .basic-post {
      padding-bottom: 80px;
      border-bottom: 1px solid g.$orange-thin;
    }
  }

  .single-pagenav {
    position: relative;
    margin-top: 40px;

    @include g.mq-down(lg) {
      margin-bottom: 120px;
    }

    .pagenavi {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      margin-bottom: 80px;

      @include g.mq-down(lg) {
        margin-top: 20px;
        margin-bottom: 40px;
      }

      li {
        width: calc(50% - 0px);
        line-height: 1;
        margin-bottom: 0;
        font-weight: 700;

        &.next {
          text-align: right;
          margin: 0;

          a {
            display: inline-block;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &::after {
              content: "";
              display: inline-block;
              vertical-align: middle;
              width: 20px;
              height: 20px;
              margin-left: 10px;
              background-image: url(../images/common/btn_slidearrow.svg);
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
              position: relative;
              transition: left .2s;
            }
          }
        }

        &.prev {
          text-align: left;
          margin: 0;

          a {
            display: inline-block;
            display: flex;
            align-items: center;

            &::before {
              content: "";
              display: inline-block;
              vertical-align: middle;
              width: 20px;
              height: 20px;
              margin-right: 10px;
              margin-left: 0;
              background-image: url(../images/common/btn_slidearrow.svg);
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
              transform: rotate(-180deg);
              position: relative;
              transition: left .2s;
            }
          }
        }
      }
    }

    .btn {
      margin: 0 auto;
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);

      @include g.mq-down(lg) {
        display: inline-block;
        width: auto;
        padding: 0;
        padding-bottom: 5px;
        border: none;
        top: 30px;

        .icon {
          position: relative;
          top: -2px;
          right: 0;
          margin-left: 5px;
        }
      }
    }
  }

  .recommend-sec {
    margin-bottom: 100px;

    @include g.mq-down(lg) {
      padding-top: 60px;
    }

    .inner {
      .midashi {
        margin-bottom: 55px;
        margin-top: -20px;

        @include g.mq-down(lg) {
          margin-top: 0;
          margin-bottom: 30px;
        }

        .en {
          display: block;
          text-align: center;
          font-weight: 700;
          font-size: 2rem;
          letter-spacing: 1px;
        }

        .ja {
          display: block;
          font-weight: 500;
          text-align: center;
        }
      }

      .list {
        .item {
          figure {
            width: 100%;
            height: g.pcvw(250);
            max-height: 250px;
            @include g.imgFit();

            @include g.mq-down(lg) {
              height: g.spvw(315);
              max-height: none;
            }
          }
        }
      }
    }
  }

  footer {
    .inquiry-sec {
      background: linear-gradient(180deg, #fff 0%, #fff calc(50% - 50px), g.$gray calc(50% - 50px), g.$gray 100%);
    }
  }
}
