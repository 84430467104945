.inview,
.inviewL,
.inviewR {
  opacity: 0;
}

$move :40px;
$moveBig :240px;
$duration : .8s;


.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: $duration;
  animation-fill-mode: both;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, $move, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInBigUp {
  animation-name: fadeInBigUp;
  animation-duration: $duration;
  animation-fill-mode: both;
}

@keyframes fadeInBigUp {
  from {
    opacity: 0;
    transform: translate3d(0, $moveBig, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  animation-name: fadeInRight;
  animation-duration: $duration;
  animation-fill-mode: both;
}

@keyframes fadeInRight {
  from {
    opacity: 0;

    transform: translate3d($move, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
  animation-duration: $duration;
  animation-fill-mode: both;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-$move, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.delay_1 {

  animation-delay: 0.1s;
}

.delay_2 {

  animation-delay: 0.2s;
}

.delay_3 {

  animation-delay: 0.3s;
}

.delay_4 {

  animation-delay: 0.4s;
}

.delay_5 {

  animation-delay: 0.5s;
}

.delay_6 {

  animation-delay: 0.6s;
}

.delay_7 {

  animation-delay: 0.7s;
}

.delay_8 {

  animation-delay: 0.8s;
}

.delay_9 {

  animation-delay: 0.9s;
}

.delay_10 {

  animation-delay: 1s;
}

.delay_20 {

  animation-delay: 2s;
}

.delay_30 {

  animation-delay: 3s;
}

.delay_40 {

  animation-delay: 4s;
}

.delay_50 {

  animation-delay: 5s;
}

.delay_60 {

  animation-delay: 6s;
}
