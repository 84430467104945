@use "../global" as g;

.pagehead-sec {
  padding-top: 130px;
  margin-bottom: 60px;

  @include g.mq-down(lg) {
    padding-top: 75px;
  }

  .inner {
    .en {
      line-height: 1.3;
      margin-bottom: 10px;
    }
  }
}
