@use "../global" as g;

.p-company {
    .mv-sec {
        margin-bottom: 161px;

        @include g.mq-down(lg) {
            margin-bottom: -40px;
        }

        .inner {
            padding: 0 33px;

            @include g.mq-down(lg) {
                padding: 0;
            }

            .title {
                @include g.mq-down(lg) {
                    top: calc(50% - 80px);
                }

                .en {
                    line-height: 1;
                    margin-bottom: 20px;

                    @include g.mq-down(lg) {
                        font-size: 3rem;
                        line-height: 1.2;
                    }

                    span {
                        font-size: 2rem;
                    }
                }

                .ja {
                    font-size: 1.6rem;
                }
            }
        }
    }

    .outline-sec {
        margin-bottom: 120px;

        @include g.mq-down(lg) {
            margin-bottom: 80px;
        }

        .inner {
            .box {
                display: flex;
                justify-content: space-between;
                flex-direction: row-reverse;

                @include g.mq-down(lg) {
                    display: block;
                }

                .photo {
                    width: 494px;
                    position: relative;

                    @include g.mq-down(lg) {
                        width: g.spvw(265);
                        margin-left: calc(100% - g.spvw(265) + 20px);
                        margin-bottom: 40px;
                    }

                    .para-box {
                        position: absolute;
                        bottom: 0;
                        right: -133px;
                        width: 494px;
                        height: 696px;
                        overflow: hidden;

                        @include g.mq-down(lg) {
                            position: static;
                            width: g.spvw(265);
                            height: g.spvw(265);
                        }
                    }

                    figure {
                        width: 100%;
                        height: 100%;
                        @include g.imgFit();
                    }
                }

                .text {
                    width: calc(100% - 494px);


                    @include g.mq-down(lg) {
                        width: 100%;
                        padding-right: 0;
                    }

                    .midashi {
                        margin-bottom: 35px;
                        font-weight: 700;

                        .en {
                            font-size: 5rem;

                            @include g.mq-down(lg) {
                                font-size: 4rem;
                            }
                        }

                        .ja {
                            font-size: 1.6rem;
                        }

                    }

                    .catch {
                        font-size: 2rem;
                        font-weight: 700;
                        letter-spacing: 2px;
                        line-height: 2;
                        margin-bottom: 35px;
                    }

                    .read {
                        font-weight: 700;
                        line-height: 2;
                    }
                }
            }
        }
    }

    .vision-sec {
        margin-bottom: 120px;

        @include g.mq-down(lg) {
            margin-bottom: 85px;
        }

        .vision-img {
            height: 516px;
            width: 100%;
            margin-bottom: 130px;

            @include g.mq-down(lg) {
                height: 100vw;
                margin-bottom: -40px;
            }

            .para-box {
                overflow: hidden;
                width: 100%;
                height: 516px;

                @include g.mq-down(lg) {
                    height: 100vw;
                }
            }

            figure {
                width: 100%;
                height: 100%;
                @include g.imgFit();
            }
        }

        .inner {
            .box {
                display: flex;
                justify-content: space-between;

                @include g.mq-down(lg) {
                    display: block;
                }

                .photo {
                    max-width: 454px;
                    width: 40%;
                    position: relative;

                    @include g.mq-down(lg) {
                        width: 100%;
                        margin-bottom: 40px;
                    }

                    .para-box {
                        overflow: hidden;
                        width: 454px;
                        height: 646px;
                        position: absolute;
                        bottom: 0;
                        left: -70px;

                        @include g.mq-down(lg) {
                            position: static;
                            width: 100%;
                            height: g.spvw(335);
                        }
                    }

                    figure {
                        width: 100%;
                        height: 100%;
                        @include g.imgFit();
                    }
                }

                .text {
                    max-width: 572px;
                    width: 55%;

                    margin-left: auto;
                    margin-right: 0;

                    @include g.mq-down(lg) {
                        max-width: 100%;
                        width: 100%;
                        margin: 0 auto;
                    }

                    .midashi {
                        margin-bottom: 35px;
                        font-weight: 700;

                        .en {
                            font-size: 5rem;

                            @include g.mq-down(lg) {
                                font-size: 4rem;
                            }
                        }

                        .ja {
                            font-size: 1.6rem;
                        }
                    }

                    .catch {
                        font-size: 2rem;
                        font-weight: 700;
                        letter-spacing: 2px;
                        line-height: 2;
                        margin-bottom: 35px;
                    }

                    .read {
                        font-weight: 700;
                        line-height: 2;
                        margin-bottom: 70px;
                    }

                }
            }
        }

    }

    .message-sec {
        padding: 120px 0;
        background: g.$gray;

        @include g.mq-down(lg) {
            padding: 80px 0;
        }

        .midashi {
            margin-bottom: 35px;
            font-weight: 700;

            .en {
                font-size: 4rem;
                letter-spacing: 2px;

                @include g.mq-down(lg) {
                    font-size: 3rem;
                }
            }

            .ja {
                font-size: 1.5rem;

                @include g.mq-down(lg) {
                    font-size: 1.4rem;
                }
            }

        }

        .block {
            padding: 0 50px;

            @include g.mq-down(lg) {
                padding: 0 10px;
            }

            .catch {
                font-size: 3rem;
                font-weight: 700;
                letter-spacing: 3px;
                margin-bottom: 70px;

                @include g.mq-down(lg) {
                    line-height: 2;
                    font-size: 2rem;
                    margin-bottom: 35px;
                }
            }

            .box {
                display: flex;
                justify-content: space-between;
                flex-direction: row-reverse;

                @include g.mq-down(lg) {
                    display: block;
                }

                .text {
                    max-width: 515px;
                    width: 50%;

                    .read {
                        font-weight: 700;
                        line-height: 2;
                    }

                    .ceo {
                        font-size: 1.5rem;
                        font-weight: 700;
                        margin-top: 40px;

                        @include g.mq-down(lg) {
                            font-size: 1.3rem;
                        }
                    }

                    @include g.mq-down(lg) {
                        max-width: 100%;
                        width: 100%;
                    }
                }

                .photo {
                    max-width: 507px;
                    width: 45%;

                    @include g.mq-down(lg) {
                        max-width: 100%;
                        width: 100%;
                        margin-bottom: 40px;
                    }
                }
            }

        }
    }

    .profile-sec {
        margin-top: 100px;

        @include g.mq-down(lg) {
            margin-top: 50px;
        }

        .midashi {
            margin-bottom: 70px;
            font-weight: 700;

            @include g.mq-down(lg) {
                margin-bottom: 50px;
            }

            .en {
                font-size: 4rem;
                letter-spacing: 2px;

                @include g.mq-down(lg) {
                    font-size: 3rem;
                }
            }

            .ja {
                font-size: 1.5rem;
            }

        }

        .block {
            width: calc(100% - 40px);
            max-width: 1000px;
            margin: 0 auto;

            @include g.mq-down(lg) {
                width: 100%;
            }

            .table {
                .row {
                    display: flex;
                    padding-bottom: 20px;
                    border-bottom: solid 1px #E0BFAB;
                    margin-bottom: 20px;


                    @include g.mq-down(lg) {
                        display: block;
                    }

                    dt {
                        max-width: 106px;
                        width: 20%;

                        @include g.mq-down(lg) {
                            width: 100%;
                            max-width: 100%;
                            margin-bottom: 10px;
                        }
                    }

                    dd {
                        max-width: 890px;
                        width: 80%;
                        font-weight: 700;

                        @include g.mq-down(lg) {
                            width: 100%;
                            max-width: 100%;
                        }

                        ul {
                            margin-bottom: 40px;

                            li {
                                padding-left: 1em;
                                text-indent: -1.3em;
                                margin-bottom: 15px;
                            }
                        }

                        dl {

                            dt {
                                margin-bottom: 10px;
                                width: 100%;
                                max-width: 100%;
                            }

                            dd {
                                margin-bottom: 30px;
                                width: 100%;
                                max-width: 100%;
                            }
                        }

                    }
                }

            }
        }
    }

    .member-sec {
        margin-top: 100px;
        padding-bottom: 110px;
        position: relative;
        z-index: 1;
        overflow: hidden;

        @include g.mq-down(lg) {
            margin-top: 60px;
        }

        &::after {
            content: "";
            display: block;
            width: g.pcvw(1116);
            height: g.pcvw(1116);
            min-width: 1116px;
            min-height: 1116px;
            background: g.$gray;
            border-radius: 50%;
            position: absolute;
            bottom: g.pcvw(-620);
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;

            @include g.mq-down(lg) {
                width: g.spvw(710);
                height: g.spvw(710);
                min-width: 710px;
                min-height: 710px;
            }
        }

        .midashi {
            margin-bottom: 70px;
            font-weight: 700;

            @include g.mq-down(lg) {
                margin-bottom: 35px;
            }

            .en {
                font-size: 4rem;
                letter-spacing: 2px;

                @include g.mq-down(lg) {
                    font-size: 3rem;
                }
            }

            .ja {
                font-size: 1.5rem;
            }

        }

        .block {
            width: calc(100% - 40px);
            max-width: 1100px;
            margin: 0 auto;

            ul {
                display: flex;
                flex-wrap: wrap;

                @include g.mq-down(lg) {
                    display: block;
                }

                li {


                    .photo {
                        margin-bottom: 15px;
                    }

                    .text {
                        .position {
                            margin-bottom: 5px;
                            display: block;
                        }

                        p {
                            font-size: 1.4rem;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }

    .service-sec {
        position: relative;
        z-index: 1;

        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 260px;
            background: #F4F2F0;
            z-index: -1;
            position: absolute;
            bottom: 0;
        }

        &::after {
            content: "";
            display: block;
            width: 1px;
            height: 40px;
            background: #fff;
            z-index: 2;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }

        .inner {
            height: 544px;
            width: 100%;
            padding: 0 33px;
            position: relative;

            @include g.mq-down(lg) {
                height: g.spvw(525);
                padding: 0;
            }

            .para-box {
                width: 100%;
                height: 544px;

                figure {
                    width: 100%;
                    height: 544px;
                    @include g.imgFit();
                }
            }

            .service_list {

                text-align: center;
                color: #fff;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;

                @include g.mq-down(lg) {
                    width: 100%;
                }

                .midashi {
                    margin-bottom: 30px;

                    .en {
                        letter-spacing: 4px;
                        font-size: 4rem;
                        font-weight: 700;

                        @include g.mq-down(lg) {
                            letter-spacing: 2px;
                        }

                        span {
                            font-weight: 200;

                            @include g.mq-down(lg) {
                                font-size: 3rem;
                            }
                        }

                    }

                    .ja {
                        font-size: 2rem;
                        font-weight: 700;
                        letter-spacing: 2px;

                        @include g.mq-down(lg) {
                            font-size: 1.8rem;
                        }
                    }
                }

                .list {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    max-width: 650px;
                    margin: 0 auto;

                    @include g.mq-down(lg) {
                        width: 90%;
                    }

                    li {
                        width: 206px;

                        font-size: 1.5rem;
                        letter-spacing: 1px;
                        font-weight: 700;
                        margin-bottom: 20px;

                        a {
                            border: solid 1px #fff;
                            border-radius: 50px;
                            display: block;
                            padding: 10px 0;
                            transition: background .2s, color .2s;

                            &:hover {
                                background: #fff;
                                color: g.$text-color;
                            }
                        }


                        @include g.mq-down(lg) {
                            max-width: 100%;
                            width: 48%;

                        }

                    }
                }

            }

        }
    }

    .field-sec {
        position: relative;
        z-index: 1;
        padding: 80px 0;
        background: #F4F2F0;

        @include g.mq-down(lg) {
            padding: 60px 0;
        }

        &::before {
            content: "";
            display: block;
            width: 1px;
            height: 40px;
            background: #E0BFAB;
            z-index: 2;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
        }

        .field_list {
            text-align: center;

            .title {
                @include g.mq-down(lg) {
                    margin-bottom: 40px;
                }

                .midashi {
                    margin-bottom: 30px;

                    .en {
                        letter-spacing: 4px;
                        font-size: 4rem;
                        font-weight: 700;

                        @include g.mq-down(lg) {
                            letter-spacing: 2px;
                        }

                        span {
                            font-weight: 200;

                            @include g.mq-down(lg) {
                                font-size: 3rem;
                            }
                        }
                    }

                    .ja {
                        font-size: 2rem;
                        font-weight: 700;
                        letter-spacing: 2px;

                        @include g.mq-down(lg) {
                            font-size: 1.8rem;
                        }
                    }
                }
            }

            .list {
                display: flex;
                justify-content: space-between;
                max-width: 1100px;
                margin: 0 auto;

                @include g.mq-down(lg) {
                    max-width: 100%;
                    width: 100%;
                    display: block;
                    padding: 0 10px;
                }

                .item {
                    max-width: 206px;
                    width: 31%;
                    margin-bottom: 20px;

                    @include g.mq-down(lg) {
                        margin-bottom: 10px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    a {
                        display: block;
                        padding: 10px 0;
                        border: solid 1px #EB7227;
                        color: #EB7227;
                        border-radius: 30px;

                        font-weight: 700;
                        letter-spacing: 1px;
                        transition: background .2s, color .2s;

                        &:hover {
                            background: g.$orange;
                            color: #fff;
                        }
                    }

                    @include g.mq-down(lg) {
                        max-width: 100%;
                        width: 100%;
                    }

                }
            }
        }
    }

    .logo-sec {
        margin-top: 80px;
        margin-bottom: 70px;
        text-align: center;
        position: relative;
        z-index: 1;

        @include g.mq-down(lg) {
            margin-top: 60px;

        }

        /*&:after{
            content: "";
            display: block;
            width: 100%;
            height: 165px;
            background-color: #fff;
            position: absolute;
            bottom: -160px;
            z-index: -1;
        }*/
        .inner {
            width: calc(100% - 40px);
            margin: 0 auto;

            .logo {
                width: 159px;
                margin: 0 auto 20px;

                @include g.mq-down(lg) {
                    width: 136px;
                    margin: 0 auto 40px;
                }

            }

            .catch {
                font-size: 1.8rem;
                font-weight: 700;
                margin-bottom: 20px;

                @include g.mq-down(lg) {
                    font-size: 1.6rem;
                    margin-bottom: 30px;
                }
            }

            .read {
                font-weight: 700;
                line-height: 2;

            }
        }
    }

    footer {
        .inquiry-sec {
            background: linear-gradient(180deg, #fff 0%, #fff calc(50% - 50px), g.$gray calc(50% - 50px), g.$gray 100%);
        }
    }
}
