@use "../global" as g;

.p-field {
  .mv-sec {
    margin-bottom: 90px;

    @include g.mq-down(lg) {
      margin-bottom: -40px;
    }

    .inner {
      padding: 0 33px;

      @include g.mq-down(lg) {
        padding: 0;
      }

      .en {
        @include g.mq-down(lg) {
          font-size: 4rem;
          letter-spacing: 2px;
        }

        span {
          font-size: 3rem;
          font-weight: 200;
          letter-spacing: 2px;
        }
      }

      .ja {
        font-weight: 700;
        letter-spacing: 1px;

        @include g.mq-down(lg) {
          font-size: 2rem;
        }
      }
    }
  }

  .outline-sec {
    margin-bottom: 120px;

    @include g.mq-down(lg) {
      margin-bottom: 80px;
    }

    .inner {
      .box {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;

        @include g.mq-down(lg) {
          display: block;
        }

        .photo {
          width: 726px;
          position: relative;

          @include g.mq-down(lg) {
            width: 100%;
            margin-bottom: 40px;
          }

          figure {
            position: absolute;
            bottom: 0;
            right: -366px;
            width: 726px;
            height: 428px;
            @include g.imgFit();

            @include g.mq-down(lg) {
              position: static;
              width: g.spvw(265);
              height: g.spvw(265);
              margin-left: calc(g.spvw(110) - 20px);
            }
          }
        }

        .text {
          width: calc(100% - 236px);


          @include g.mq-down(lg) {
            width: 100%;
            padding-right: 0;
          }

          .midashi {
            font-size: 2rem;
            font-weight: 700;
            margin-bottom: 20px;

            @include g.mq-down(lg) {
              margin-bottom: 10px;
            }
          }

          .catch {
            font-size: 2rem;
            font-weight: 700;
            margin-bottom: 20px;
          }

          .read {
            font-weight: 700;
            line-height: 2;
          }
        }
      }
    }
  }

  .content-sec {
    margin-bottom: 194px;

    @include g.mq-down(md) {
      margin-bottom: 100px;
    }

    .inner {
      .list {
        padding-left: calc(50vw - 1366px / 2);

        @include g.mq-down(lg) {
          padding-left: 0;
        }

        .item {
          width: 100%;
          display: flex;
          align-items: flex-end;
          margin-bottom: 7px;

          @include g.mq-down(lg) {
            display: block;
            margin-bottom: 40px;
          }

          &:nth-last-of-type(1) {
            margin-bottom: 0;
          }

          .photo {
            width: 755px;
            height: 400px;

            @include g.mq-down(lg) {
              width: 100%;
              height: 100vw;
              margin-bottom: 30px;
            }

            figure {
              width: 100%;
              height: 100%;
              @include g.imgFit();
            }
          }

          .text {
            width: calc(100vw - 755px);
            padding-left: 118px;
            padding-bottom: 20px;

            @include g.mq-down(lg) {
              width: 100%;
              padding-left: 30px;
            }

            .midashi {
              font-weight: 700;
              padding-bottom: 15px;
              margin-bottom: 25px;
              border-bottom: 1px solid g.$orange-thin;

              .en {
                margin-bottom: 2px;

                span {
                  font-size: 2rem;
                  font-weight: 200;
                  color: g.$orange;
                  line-height: 1.2;
                  display: block;
                }

                font-size: 3rem;
                font-weight: 700;
                letter-spacing: 2px;
                line-height: 1.4;

              }

              .ja {
                font-size: 1.4rem;
              }
            }

            .read {
              width: 100%;
              max-width: 500px;
              line-height: 2;
              font-weight: 500;
              margin-bottom: 20px;

              @include g.mq-down(lg) {
                padding-right: 30px;
              }
            }

            .btn {
              display: inline-block;
            }
          }
        }
      }
    }
  }

  footer {
    .inquiry-sec {
      background: linear-gradient(180deg, #fff 0%, #fff calc(50% - 50px), g.$gray calc(50% - 50px), g.$gray 100%);
    }
  }
}
