@use "../global" as g;

header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 92.8px;
  background: #fff;
  z-index: 100;

  @include g.mq-down(lg) {
    height: 80px;
  }

  .inner {
    padding: 0 60px;

    @include g.mq-down(xxl) {
      padding: 0 20px;
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;


      .logo {
        width: 99px;
        height: 30px;
      }

      .menu {
        &.pc-menu {
          @include g.mq-down(lg) {
            display: none;
          }

        }

        &.sp-menu {
          @include g.mq-up(lg) {
            display: none;
          }
        }

        display: flex;
        align-items: center;
        justify-content: space-between;


        .main-nav {
          @include g.mq-down(lg) {
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top: 80px;
            left: 0px;
            width: 100%;
            height: calc(100vh - 80px);
            background-color: #F4F2F0;
            padding: 0 20px;
            overflow: scroll;
          }

          ul {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 50px;

            @include g.mq-down(xxl) {
              gap: 30px;
            }

            @include g.mq-down(lg) {
              display: block;
            }

            &>li {
              padding: 36px 0;
              position: relative;

              @include g.mq-down(lg) {
                padding: 0;

              }

              &:nth-of-type(2) {
                position: static;

                &>a {}
              }

              &>a {
                @include g.mq-up(lg) {
                  .deco {
                    display: none;
                  }
                }

                @include g.mq-down(lg) {
                  padding: 25px 0 15px;
                  margin-bottom: 10px;
                  border-bottom: 1px solid #E0BFAB;
                }
              }

              &:hover {
                &>a {
                  @include g.mq-up(lg) {
                    color: g.$orange;
                  }



                }
              }

              &>a {
                font-weight: 700;

                .en {
                  @include g.mq-down(lg) {
                    letter-spacing: 2px;
                  }
                }

                .ja {
                  display: none;

                  @include g.mq-down(lg) {
                    display: inline-block;
                    margin-left: 15px;
                    font-size: 1.2rem;
                    font-weight: 400;
                  }
                }
              }

              &:nth-child(1) {
                .child-menu {
                  width: 270px;

                  @include g.mq-down(lg) {
                    width: 100%;
                  }

                  li {
                    margin-bottom: 25px;

                    &:nth-last-child(1) {
                      margin-bottom: 0;
                    }

                    @include g.mq-down(lg) {
                      margin: 0;
                      padding: 14px 0;
                      border-bottom: 1px solid #EAD8CD;
                    }
                  }
                }
              }

              &:nth-child(2) {
                .child-menu {
                  width: 100%;
                  left: 0;
                  padding: 50px;
                  display: flex;
                  justify-content: space-between;

                  @include g.mq-down(lg) {
                    display: none;
                    padding: 30px 10px 0;
                  }

                  li {
                    width: 15%;

                    @include g.mq-down(lg) {
                      width: 100%;
                      margin-bottom: 40px;
                    }

                    &>a {
                      display: block;
                      border: 1px solid g.$orange;
                      border-radius: 50px;
                      padding: 5px 0;
                      text-align: center;
                      color: g.$orange;
                      margin-bottom: 30px;
                      pointer-events: none;

                      @include g.mq-down(lg) {
                        margin-bottom: 15px;
                        padding: 10px 0;
                      }
                    }

                    .grandchild-menu {
                      a {
                        margin-bottom: 15px;

                        @include g.mq-down(lg) {
                          border-bottom: 1px solid #EAD8CD;
                          padding: 15px 0;
                          margin-bottom: 0;
                          justify-content: flex-start;
                        }
                      }
                    }
                  }
                }
              }

              &:nth-child(3) {
                .child-menu {
                  width: 320px;

                  @include g.mq-down(lg) {
                    width: 100%;
                  }

                  li {
                    margin-bottom: 25px;

                    &:nth-last-child(1) {
                      margin-bottom: 0;
                    }

                    @include g.mq-down(lg) {
                      margin: 0;
                      padding: 14px 0;
                      border-bottom: 1px solid #EAD8CD;
                    }
                  }
                }
              }

              &:nth-child(5) {
                .child-menu {
                  width: 320px;

                  @include g.mq-down(lg) {
                    width: 100%;
                  }

                  li {
                    margin-bottom: 25px;

                    &:nth-last-child(1) {
                      margin-bottom: 0;
                    }

                    @include g.mq-down(lg) {
                      margin: 0;
                      padding: 14px 0;
                      border-bottom: 1px solid #EAD8CD;
                    }
                  }
                }
              }

              .child-menu {
                opacity: 0;
                visibility: hidden;
                position: absolute;
                top: 93px;
                left: -112px;
                background: #EFEFEF;
                padding: 50px 70px;

                @include g.mq-down(lg) {
                  display: none;
                  position: static;
                  background: none;
                  padding: 0 10px;
                  opacity: 1;
                  visibility: visible;
                }

                li {
                  font-weight: bold;
                }
              }
            }


          }

          .basicinfo {
            @include g.mq-up(lg) {
              display: none;
            }

            margin-top: 40px;
            padding: 0 10px 100px;

            li {


              a {
                font-size: 1.3rem;
                padding: 15px 0;
                margin-bottom: 0;
              }

            }
          }
        }

        .inquiry-nav {
          padding-left: 90px;
          position: relative;

          @include g.mq-down(xxl) {
            padding-left: 60px;
          }

          @include g.mq-down(lg) {
            padding-left: 0;
            padding-right: 90px;
            position: static;
          }

          &::before {
            content: "";
            width: 1px;
            height: 40px;
            background: #dedede;
            position: absolute;
            top: 50%;
            left: 44.5px;
            transform: translateY(-50%);

            @include g.mq-down(xxl) {
              left: 29.5px;
            }

            @include g.mq-down(lg) {
              left: auto;
              right: 80px;
            }
          }

          ul {
            &>li {
              padding: 36px 0;
              position: relative;

              @include g.mq-down(lg) {
                padding: 22.46px 0 36.46px;
                position: static;
              }

              &>a {
                font-weight: 700;
                color: g.$orange;
                display: inline-block;
                position: relative;

                @include g.mq-down(lg) {
                  color: g.$text-color;
                  font-size: 1.1rem;
                  letter-spacing: 1px;
                }

                &::after {
                  content: "";
                  display: block;
                  width: 100%;
                  height: 3px;
                  background: g.$orange;
                  position: absolute;
                  bottom: -6px;
                  left: 0;
                }
              }

              .child-menu {
                background: g.$orange;
                opacity: 0;
                visibility: hidden;
                position: absolute;
                top: 93px;
                right: -60px;
                padding: 50px 60px;
                width: 390px;

                @include g.mq-down(lg) {
                  width: 100%;
                  height: calc(100vh - 80px);
                  top: 80px;
                  right: 0;
                  padding: 32px 20px;
                }

                li {
                  font-weight: bold;
                  margin-bottom: 25px;
                  color: #fff;

                  @include g.mq-down(lg) {
                    margin-bottom: 0;
                  }

                  &:nth-last-child(1) {
                    margin-bottom: 0;

                    @include g.mq-down(lg) {
                      padding-bottom: 0;
                    }
                  }

                  a {
                    border-bottom: 1px solid #efefef;
                    padding: 18px 0 14px;
                    font-size: 1.4rem;
                    letter-spacing: 2px;
                  }
                }
              }
            }
          }
        }

        .basicinfo-nav {
          ul {
            padding: 0 10px;
          }
        }

        .menu-btn {
          background: #fff;
          position: absolute;
          top: 0;
          right: 0;
          z-index: 1;
          cursor: pointer;

          .word {
            color: g.$text-color;
            font-size: 1rem;
            letter-spacing: 0;
            text-align: center;
            margin: 0;
            transition: margin-left .2s;



            &.active {
              margin-left: -3px;
            }
          }
        }
      }
    }
  }
}
