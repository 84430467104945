@use "../global" as g;

.wp-pagenavi {
  text-align: center;

  a,
  span {
    font-size: 0;
    padding: 0;
    border: none;
    margin: 0 6px;
  }

  a.page {


    &::before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      border: 1px solid #000;
      background: #fff;
      border-radius: 50%;
    }

  }

  span.current {


    &::before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      border: 1px solid g.$orange;
      background: g.$orange;
      border-radius: 50%;
    }
  }

  .nextpostslink {
    font-size: 0;
    border: none;
    margin-left: 30px;

    &::before {
      content: "";
      display: inline-block;
      width: 24px;
      height: 24px;
      background-image: url(../images/common/btn_slidearrow.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      top: 7px;
    }
  }

  .previouspostslink {
    font-size: 0;
    border: none;
    margin-right: 30px;

    &::before {
      content: "";
      display: inline-block;
      width: 24px;
      height: 24px;
      background-image: url(../images/common/btn_slidearrow.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      transform: rotate(-180deg);
      position: relative;
      top: 7px;
    }
  }
}
