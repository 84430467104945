@use "../global" as g;

.p-contact {

    .outline-sec {
        margin-bottom: 70px;

        .inner {
            .read {
                font-weight: 700;
                line-height: 2;
                margin-left: 50px;

                @include g.mq-down(lg) {
                    margin-left: 0;
                }
            }
        }
    }
}
