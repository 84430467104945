@use "../global" as g;

.p-esg {
  .mv-sec {

    .inner {
      padding: 0 33px;

      @include g.mq-down(lg) {
        padding: 0;
      }
    }
  }

  .outline-sec {
    padding: 90px 0 100px;
    position: relative;

    @include g.mq-down(lg) {
      padding: 85px 0 70px;
    }

    .inner {
      width: calc(100% - 60px);
    }

    figure {
      width: 479px;
      height: 817px;
      position: absolute;
      bottom: 0;
      right: 0;

      @include g.mq-down(lg) {
        width: 226px;
        height: 387px;
        bottom: calc(100% - 130px);
        right: -82px;
      }
    }

    .inner {
      .catch {
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 20px;

        @include g.mq-down(lg) {
          font-size: 1.8rem;
        }
      }

      .read {

        line-height: 2;
      }
    }
  }

  .content-sec {
    background: g.$gray;
    padding-bottom: 150px;

    @include g.mq-down(lg) {
      padding: 80px 0 70px;
    }

    .box {
      padding-left: calc(50vw - 1000px / 2);

      @include g.mq-down(lg) {
        padding: 0 0 0 20px;
      }
    }

    .item {

      display: flex;
      align-items: flex-end;
      margin-bottom: 60px;

      @include g.mq-down(lg) {
        display: block;
        margin-bottom: 90px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .photo {
        width: 382px;

        @include g.mq-down(lg) {
          width: 100%;
          padding-right: 20px;
          margin-bottom: 25px;
        }

        figure {
          width: 100%;
          height: 502px;
          margin-bottom: 0;
          @include g.imgFit();

          @include g.mq-down(lg) {
            height: g.spvw(438);
          }
        }
      }

      .text {
        width: calc(100% - 382px);
        padding-left: unquote('max(60px, 120px)');

        @include g.mq-down(lg) {
          width: 100%;
          padding: 0;
        }

        .midashi {
          font-size: 2rem;
          font-weight: 700;
          letter-spacing: 1px;
          padding-bottom: 15px;
          margin-bottom: 20px;
          border-bottom: 1px solid g.$orange-thin;
        }

        .catch {
          font-size: 1.6rem;
          font-weight: 700;
          margin-bottom: 10px;
          width: 100%;
          max-width: 550px;

          @include g.mq-down(lg) {
            max-width: 100%;
            padding-right: 20px;
          }
        }

        .read {
          line-height: 2;
          width: 100%;
          max-width: 550px;
          margin-bottom: 20px;

          @include g.mq-down(lg) {
            max-width: 100%;
            padding-right: 20px;
            margin-bottom: 30px;
          }
        }

        .list {
          display: flex;
          flex-wrap: wrap;
          gap: 1px;
          width: 100%;
          max-width: 550px;

          @include g.mq-down(lg) {
            max-width: 100%;
            padding-right: 20px;
          }

          li {
            width: 80px;

            @include g.mq-down(lg) {
              width: calc(33.3333% - 1px);
            }
          }
        }
      }
    }
  }

  footer {
    .inquiry-sec {
      margin-top: 90px;
      background: linear-gradient(180deg, #fff 0%, #fff calc(50% - 50px), g.$gray calc(50% - 50px), g.$gray 100%);

      @include g.mq-down(lg) {
        margin-top: 70px;
      }
    }
  }
}
