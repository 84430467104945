@use "../global" as g;

.p-work {
  .pagehead-sec {
    padding-top: 130px;
    margin-bottom: 120px;

    @include g.mq-down(lg) {
      padding-top: 75px;
    }
  }

  .filtering-sec {
    padding: 45px 0;
    background: g.$gray;

    @include g.mq-down(lg) {
      padding: 30px 0 24px;
    }

    .inner {
      .block {

        .list {
          display: flex;
          align-items: center;
          justify-content: center;

          @include g.mq-down(lg) {
            flex-wrap: wrap;
            justify-content: space-between;
          }

          .item {
            width: 171px;
            margin: 0 7px;

            @include g.mq-down(lg) {
              width: 49%;
              margin: 0 0 2% 0;
            }

            a {
              display: block;
              width: 100%;
              padding: 10px 0;
              text-align: center;
              color: g.$orange;
              border: 1px solid g.$orange;
              border-radius: 50px;

              &.active {
                background: g.$orange;
                color: #fff;
              }
            }

            &.is-active {
              a {
                background: g.$orange;
                color: #fff;

              }
            }
          }
        }
      }
    }
  }

  .content-sec {
    .inner {
      width: 100%;
      padding-top: 80px;
      padding-bottom: 0px;
      padding-left: calc(50vw - 1000px / 2);

      @include g.mq-down(lg) {
        padding: 40px 20px;
      }

      .item {
        padding-bottom: 150px;
        display: none;

        @include g.mq-down(lg) {
          padding-bottom: 40px;
        }

        &.is-active {
          display: block;
        }

        .midashi {
          font-size: 2rem;
          font-weight: 700;
          color: g.$orange;
          padding-bottom: 30px;
          margin-bottom: 50px;
          border-bottom: 1px solid g.$orange;
        }

        dl {
          width: 100%;
          max-width: 1000px;
          display: flex;
          margin-bottom: 15px;

          @include g.mq-down(lg) {
            display: block;
            margin-bottom: 60px;
          }

          dt {
            width: 233px;
            height: 233px;

            @include g.mq-down(lg) {
              width: g.spvw(315);
              height: g.spvw(315);
              margin: 0 auto 20px;
            }

            figure {
              width: 100%;
              height: 100%;
              @include g.imgFit();
            }
          }

          dd {
            width: calc(100% - 233px);
            padding-left: 60px;
            margin-top: 5px;

            @include g.mq-down(lg) {
              padding: 0 10px;
              width: 100%;
              margin-top: 0;
            }

            .komidashi {
              font-size: 1.8rem;
              font-weight: 700;
              padding-bottom: 15px;
              margin-bottom: 20px;
              border-bottom: 1px solid g.$orange;
            }

            .read {
              font-weight: 700;
              line-height: 2;
              margin-bottom: 20px;
            }

            .btn {
              a {
                @include g.mq-down(lg) {
                  justify-content: flex-start;
                }
              }
            }
          }
        }
      }
    }
  }
}
