@use "../global" as g;

.aside-block {
  width: 100%;
  margin-bottom: 80px;

  @include g.mq-down(lg) {
    margin-bottom: 40px;
  }

  &:first-child {
    margin-top: 20px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .komidashi {
    border-bottom: 1px solid g.$orange-thin;
    margin-bottom: 15px;
    padding-bottom: 10px;
    font-weight: 700;

    @include g.mq-down(lg) {
      font-size: 1.6rem;
    }
  }

  .category-list {
    width: 100%;
    max-width: 180px;
    padding-right: 20px;

    @include g.mq-down(lg) {
      max-width: 100%;
      padding-right: 30px;
      display: flex;
      flex-wrap: wrap;
      gap: 2%;
    }

    li {
      width: 100%;
      max-width: 112px;
      margin-bottom: 10px;

      @include g.mq-down(lg) {
        width: 32%;
        max-width: 32%;

      }

      &:last-child {
        margin-bottom: 0;
      }

      a {
        display: block;
        color: g.$orange;
        padding: 0;
        text-align: center;
        border: 1px solid g.$orange;
        border-radius: 50px;


        @include g.mq-down(lg) {
          width: 100%;
          padding: 1px 0px;
          font-size: 1.2rem;
        }

        &:hover {
          background: g.$orange;
          color: #fff;
        }
      }
    }
  }

  .acc-content {
    width: 100%;
    max-width: 180px;

    @include g.mq-down(lg) {
      max-width: calc(100% - 30px);

    }
  }
}

.selectbox {
  width: 100%;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    z-index: 1;
    margin: auto;
    padding: 0;
    border-top: 6px solid #ccc;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    pointer-events: none;
  }

  select {
    background: #fff;
    background-image: none;
    width: 100%;
    padding: 7px 38px 7px 5px;
    padding-right: 1em;
    border: none;
    border: 1px solid #ccc;
    color: #ccc;
    text-indent: .01px;
    cursor: pointer;
    appearance: none;
    box-shadow: none;
    outline: none;
    text-overflow: ellipsis;
    position: relative;

  }
}
