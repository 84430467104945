@use "../global" as g;

.p-field-single {
  .mv-sec {
    margin-bottom: 90px;

    @include g.mq-down(lg) {
      margin-bottom: 60px;
    }

    .inner {
      padding: 0 33px;

      @include g.mq-down(lg) {
        padding: 0;
      }

      .en {
        @include g.mq-down(lg) {
          font-size: 4rem;
          letter-spacing: 2px;
        }

        span {
          font-size: 3rem;
          font-weight: 200;
          letter-spacing: 2px;
        }
      }

      .ja {
        font-weight: 700;
        letter-spacing: 1px;

        @include g.mq-down(lg) {
          font-size: 2rem;
        }
      }
    }
  }

  .outline-sec {
    margin-bottom: 120px;

    @include g.mq-down(lg) {
      margin-bottom: 80px;
    }

    .inner {
      padding-left: calc(50vw - 1366px / 2);

      @include g.mq-down(lg) {
        width: calc(100% - 60px);
        margin: 0 auto;
        padding-left: 0;
      }

      .box {
        display: flex;


        @include g.mq-down(lg) {
          display: block;
        }

        .photo {
          width: 680px;
          position: relative;

          @include g.mq-down(lg) {
            width: 100%;
            margin-bottom: 40px;
          }

          figure {

            width: 100%;
            height: 490px;
            @include g.imgFit();

            @include g.mq-down(lg) {
              position: static;
              width: 100%;
              height: g.spvw(239);
            }
          }
        }

        .text {
          width: calc(100% - 680px);
          max-width: 518px;
          padding-left: 80px;

          @include g.mq-down(lg) {
            width: 100%;
            padding-left: 0;
          }

          .midashi {
            font-size: 2rem;
            font-weight: 700;
            margin-bottom: 20px;

            @include g.mq-down(lg) {
              margin-bottom: 10px;
            }
          }

          .catch {
            font-size: 2rem;
            font-weight: 700;
            margin-bottom: 20px;

            @include g.mq-down(lg) {
              font-size: 1.8rem;
            }
          }

          .read {

            line-height: 2;

            @include g.mq-down(lg) {
              line-height: 1.8;
            }
          }
        }
      }
    }
  }

  .ourservice-sec {
    padding: 80px 0 60px;
    background: g.$gray;

    @include g.mq-down(lg) {
      padding: 60px 0;
    }

    .inner {
      .midashi {
        text-align: center;
        margin-bottom: 30px;

        .en {
          font-size: 4rem;
          font-weight: 700;

          @include g.mq-down(lg) {
            font-size: 3rem;
          }

          span {
            font-weight: 200;
          }
        }
      }

      .list {
        .item {
          a {
            display: block;
            @include g.image-link_zoom();
          }

          figure {
            width: 100%;
            height: 100%;
            max-height: 250px;
            @include g.imgFit();
          }

          .caption {
            @include g.mq-down(lg) {
              font-size: 1.1rem;
            }

            .title {
              text-align: center;
              font-weight: 700;
              margin-bottom: 10px;
            }

            .cat {
              text-align: center;



              span {
                display: inline-block;
                color: g.$orange;
                border: 1px solid g.$orange;
                padding: 3px 10px;
                border-radius: 50px;


              }
            }
          }


        }
      }
    }
  }

  footer {
    .inquiry-sec {
      background: linear-gradient(180deg, #fff 0%, #fff calc(50% - 50px), g.$gray calc(50% - 50px), g.$gray 100%);
    }
  }
}
