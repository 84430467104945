@use "../global" as g;

footer {
  background: #fff;
  position: relative;
  z-index: 3;

  .inquiry-sec {
    background: g.$gray;
    padding-bottom: 100px;

    @include g.mq-down(lg) {
      padding-bottom: 60px;
    }

    .list {
      display: flex;
      background: #fff;

      @include g.mq-down(lg) {
        display: block;
      }

      .item {
        width: 33.3333%;
        height: 300px;
        background: #fff;
        color: #fff;
        text-align: center;

        @include g.mq-down(lg) {
          width: 100%;
          height: 170px;
        }

        &:nth-child(even) {
          a {
            background: g.$orange;
          }

        }

        a {
          background: g.$orange-dark;
          display: block;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: opacity .2s;

          @include g.mq-down(lg) {
            justify-content: flex-start;
            text-align: left;
            padding: 0 40px;
          }

          .unit {
            @include g.mq-down(lg) {
              position: relative;
              width: 100%;
            }
          }

          .en {
            font-size: 2rem;
            font-weight: 200;
            letter-spacing: 2px;

            @include g.mq-down(lg) {
              line-height: 1.4;
              margin-bottom: 2px;
            }


            span {
              font-size: 2.4rem;
              display: block;
              font-weight: 700;
            }
          }

          .ja {
            font-size: 1.4rem;
            font-weight: 500;
            letter-spacing: 2px;

            @include g.mq-down(lg) {
              letter-spacing: 1px;
            }
          }

          .icon {
            width: 40px;
            height: 40px;
            background: #fff;
            border-radius: 50%;
            position: relative;
            margin: 20px auto 0;
            transform-origin: center;
            transform: scale(1);
            transition: transform .2s;

            &::before {
              content: "";
              display: block;
              width: 4px;
              height: 4px;
              border-top: 1px solid g.$orange;
              border-right: 1px solid g.$orange;
              -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
              position: absolute;
              left: calc(50% - 2px);
              top: calc(50% - 2px);
              z-index: 2;
            }

            @include g.mq-down(lg) {
              position: absolute;
              top: calc(50% - 40px);
              right: 0;
            }
          }

          &:hover {
            opacity: 0.85;

            .icon {
              transform: scale(1.2);
            }
          }
        }
      }
    }
  }

  .group-sec {
    padding-bottom: 75px;
    border-bottom: 1px solid #efefef;

    @include g.mq-down(lg) {
      padding-bottom: 35px;
    }

    .inner {
      .midashi {
        font-weight: 700;
        text-align: center;
        font-size: 1.4rem;
        margin-top: -10px;
        margin-bottom: 50px;
      }

      .list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        li {
          width: percentage(245px / 1100px);

          @include g.mq-down(lg) {
            width: calc(50% - 4px);
            margin-bottom: 17px;
          }

          a {
            @include g.image-link_zoom();
          }

          figure {
            margin-bottom: 10px;


            @include g.mq-down(lg) {
              margin-bottom: 5;
            }
          }

          .caption {
            @include g.mq-down(lg) {
              position: relative;
            }

            .en {
              font-size: 1rem;
              color: g.$orange;
            }

            .ja {
              font-size: 1.2rem;
              font-weight: 700;

              @include g.mq-down(lg) {
                font-size: 1rem;
                padding-right: 0;
                display: block;
              }

              .icon {
                @include g.mq-down(lg) {
                  position: absolute;
                  top: 2px;
                  right: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .nav-sec {
    padding: 50px 0 0;

    .inner {
      padding-bottom: 30px;
      border-bottom: 1px solid #efefef;

      .logo {
        width: 140px;
        height: 44px;
        margin: 0 auto 40px;

        @include g.mq-down(lg) {
          width: 132px;
          height: 40px;
        }
      }

      .box {
        display: flex;
        gap: 36px;

        @include g.mq-down(lg) {
          display: block;
        }

        .col {
          width: percentage(150px / 1240px);

          @include g.mq-down(lg) {
            width: 100%;
          }

          &:nth-of-type(2) {
            width: percentage(503px / 1240px);

            @include g.mq-down(lg) {
              width: 100%;
            }
          }
        }

        .midashi {
          font-weight: 700;
          font-size: 1.6rem;
          border-bottom: 1px solid #efefef;
          margin-bottom: 18px;
          padding-bottom: 12px;

          @include g.mq-up(lg) {
            pointer-events: none;
          }

          .en {
            @include g.mq-down(lg) {
              letter-spacing: 2px;
            }
          }

          .ja {
            font-size: 1.35rem;
            margin-left: 15px;

            @include g.mq-up(lg) {
              display: none;
            }
          }

          .deco {
            @include g.mq-up(lg) {
              display: none;
            }
          }
        }



        .block {
          @include g.mq-up(lg) {
            display: flex !important;
            gap: 28px;
          }

          @include g.mq-down(lg) {
            display: block;
          }

          ul {
            li {
              .komidashi {
                @include g.mq-down(lg) {
                  display: block;
                  border: 1px solid g.$orange;
                  border-radius: 50px;
                  color: g.$orange;
                  text-align: center;
                  padding: 10px 0;
                }
              }

              ol {
                &>li {
                  a {
                    .deco {
                      @include g.mq-up(lg) {
                        display: none;
                      }
                    }

                    @include g.mq-down(lg) {
                      border-bottom: 1px solid #efefef;
                      padding: 10px 0;

                      .ja {
                        font-weight: 700;
                        margin-right: 15px;
                      }
                    }

                  }
                }
              }
            }
          }
        }

        ul {
          padding-bottom: 30px;

          @include g.mq-down(lg) {
            padding: 0 10px 30px;
          }

          &>li {
            margin-bottom: 15px;

            &>a {
              font-weight: 700;
              margin-bottom: 15px;

              .deco {
                @include g.mq-up(lg) {
                  display: none;
                }
              }

              @include g.mq-down(lg) {
                border-bottom: 1px solid #efefef;
                padding: 10px 0;

                .ja {
                  font-weight: 700;
                  margin-right: 15px;
                }
              }
            }

            ol {
              margin-bottom: 30px;

              @include g.mq-down(lg) {
                margin-bottom: 50px;
              }

              &>li {
                margin-bottom: 5px;
              }
            }
          }
        }

        .acc-target {
          @include g.mq-down(lg) {
            display: none;
          }
        }
      }
    }
  }

  .basicinfo-sec {
    padding: 44px 0 32px;

    @include g.mq-down(lg) {
      padding: 15px 0;
    }

    .inner {
      a {
        display: inline-block;
        margin-right: 30px;

        @include g.mq-down(lg) {
          margin-right: 15px;
          font-size: 1.1rem;
        }
      }
    }
  }

  .copyright-sec {
    background: g.$orange;
    padding: 25px 0;
    color: g.$white;
    text-align: center;
    font-size: 1rem;
    letter-spacing: 1px;

    @include g.mq-down(lg) {
      letter-spacing: 0.3px;
    }
  }
}
