@use "../global" as g;

//slick default style
.slick-dots {
  //bottom: -30px !important;
}

.slick-dots li {
  margin: 0 !important;

  button:before {
    font-family: sans-serif !important;
    content: "○" !important;
    color: g.$text-color !important;
    font-weight: 700;
    opacity: 1 !important;

  }

  &.slick-active button:before {
    content: "●" !important;
    color: g.$orange !important;
  }
}

.slick-loading .slick-list {
  background: none !important;
}

.slick-prev:before,
.slick-next:before {
  content: "" !important;
  background-image: url(../images/common/btn_slidearrow.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 24px;
  height: 24px;
  display: block;
  opacity: 1 !important;


}

.slick-prev:before {
  transform: rotate(-180deg);
}

.slick-prev,
.slick-next {
  width: 24px !important;
  height: 24px !important;
  z-index: 1;


}

// slick スライドコントロールスタイル

.control-style1 {

  .slick-prev {
    top: calc(50% - 30px);
    left: -50px;

    @include g.mq-down(lg) {
      left: 20px;
    }
  }

  .slick-next {
    top: calc(50% - 30px);
    right: -50px;

    @include g.mq-down(lg) {
      right: 20px;
    }
  }

}
