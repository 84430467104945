@use "../global" as g;

main {
  margin-top: 92.8px;

  @include g.mq-down(lg) {
    margin-top: 80px;
  }

}

.ly_w1000 {
  width: calc(100% - 40px);
  max-width: 1000px;
  margin: 0 auto;
}

.ly_w1100 {
  width: calc(100% - 40px);
  max-width: 1100px;
  margin: 0 auto;
}

.ly_w1200 {
  width: calc(100% - 40px);
  max-width: 1200px;
  margin: 0 auto;
}

.ly_w1240 {
  width: calc(100% - 40px);
  max-width: 1240px;
  margin: 0 auto;
}

.ly_w1300 {
  width: calc(100% - 40px);
  max-width: 1300px;
  margin: 0 auto;
}

.ly_4col {
  display: flex;
  gap: percentage(33px / 1100px);
  flex-wrap: wrap;

  @include g.mq-down(lg) {
    display: block;
  }

  .item {
    width: percentage(250px / 1100px);
    margin-bottom: 33px;

    @include g.mq-down(lg) {
      width: 100%;

      a {
        .deco {
          display: none;
        }
      }
    }
  }

  &.--sp2col {
    @include g.mq-down(lg) {
      display: flex;
      gap: 15px;
    }

    .item {
      @include g.mq-down(lg) {
        width: calc(50% - 7.5px);
        margin-bottom: 15px;
      }
    }
  }
}

.ly_3col {
  display: flex;
  gap: percentage(43px / 1100px);
  flex-wrap: wrap;

  @include g.mq-down(lg) {
    display: block;
  }

  .item {
    width: percentage(337px / 1100px);
    margin-bottom: 33px;

    @include g.mq-down(lg) {
      width: 100%;

      a {
        .deco {
          display: none;
        }
      }
    }
  }

  &.--sp2col {
    @include g.mq-down(lg) {
      display: flex;
      gap: 15px;
    }

    .item {
      @include g.mq-down(lg) {
        width: calc(50% - 7.5px);
        margin-bottom: 15px;
      }
    }
  }
}
