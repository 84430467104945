@use "../global" as g;

.p-recruit {
    .mv-sec {
        margin-bottom: 150px;

        @include g.mq-down(lg) {
            margin-bottom: -40px;
        }

        .inner {
            padding: 0px;

            @include g.mq-down(lg) {
                padding: 0;
            }

            .bl_title1 {
                height: 650px;

                @include g.mq-down(lg) {
                    height: g.spvw(450);
                }

                .title {
                    top: calc(50% - 100px);

                    .en {
                        @include g.mq-down(lg) {
                            font-size: 3rem;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }

    .outline-sec {
        position: relative;
        margin-bottom: 100px;

        @include g.mq-down(lg) {
            margin-bottom: 70px;
        }

        .inner {
            @include g.mq-down(lg) {
                width: calc(100% - 60px);
            }

            .box {
                display: flex;
                justify-content: space-between;
                flex-direction: column-reverse;
                position: relative;

                @include g.mq-down(lg) {
                    display: block;
                }

                .photo {
                    max-width: 445px;
                    width: 40%;
                    position: absolute;
                    right: 50px;
                    bottom: 0;

                    @include g.mq-down(lg) {
                        width: 100%;
                        max-width: 100%;
                        position: static;
                        margin-bottom: 30px;
                    }

                    .para-box {
                        width: 100%;
                        height: 640px;
                        overflow: hidden;

                        @include g.mq-down(lg) {
                            height: g.spvw(453);
                        }

                        figure {
                            width: 100%;
                            height: 100%;
                            @include g.imgFit();
                        }
                    }
                }

                .text {
                    width: 50%;
                    max-width: 600px;

                    @include g.mq-down(lg) {
                        width: 100%;
                    }

                    .midashi {
                        font-size: 4rem;
                        letter-spacing: 4px;
                        font-weight: 700;
                        margin-left: 20px;
                        margin-bottom: 20px;

                        @include g.mq-down(lg) {
                            font-size: 3rem;
                            letter-spacing: 2px;
                        }

                        span {
                            font-weight: 200;
                        }

                        @include g.mq-down(lg) {
                            margin-left: 0;
                        }
                    }

                    .catch {
                        font-size: 2.5rem;
                        font-weight: 700;
                        letter-spacing: 2px;
                        margin-bottom: 25px;
                        padding-left: 100px;

                        @include g.mq-down(lg) {
                            font-size: 2rem;
                            padding-left: 0;
                            line-height: 2;
                        }
                    }

                    .read {
                        width: 100%;
                        font-weight: 500;
                        line-height: 2;
                        padding-left: 100px;

                        @include g.mq-down(lg) {
                            max-width: 100%;
                            width: 100%;
                            padding-left: 0;
                        }
                    }
                }


            }
        }
    }

    .title {
        .midashi {
            .en {
                font-size: 4rem;
                font-weight: 700;
                margin-bottom: 5px;
                letter-spacing: 3px;

                @include g.mq-down(lg) {
                    font-size: 3rem;
                }

                .thin {
                    font-weight: 200;
                    margin-right: 15px;
                }
            }

            .ja {
                font-size: 2rem;
                font-weight: 700;

                @include g.mq-down(lg) {
                    font-size: 1.8rem;
                }
            }
        }
    }

    .principle-sec {
        position: relative;
        z-index: 1;

        .box {
            padding: 0 33px;
            height: 520px;
            position: relative;

            @include g.mq-down(lg) {
                padding: 0;
                height: 100vw;
            }

            .para-box {
                width: 100%;
                height: 100%;
                overflow: hidden;

                @include g.mq-down(lg) {
                    height: 100vw;
                }

                figure {
                    width: 100%;
                    height: 100%;
                    @include g.imgFit();

                    picture {
                        @include g.mq-down(lg) {
                            height: 100vw;
                        }
                    }
                }
            }

            .midashi {
                position: absolute;
                top: calc(50% - 30px);
                left: calc(50vw - 1280px / 2);
                transform: translateY(-50%);
                color: #fff;

                @include g.mq-down(lg) {
                    width: calc(100% - 60px);
                    left: 30px;
                }

                .en {
                    font-size: 4rem;
                    font-weight: 700;
                    letter-spacing: 4px;
                    margin-bottom: 5px;

                    @include g.mq-down(lg) {
                        font-size: 3rem;
                    }

                    span {
                        font-weight: 200;
                    }
                }

                .ja {
                    padding-left: 85px;
                    font-size: 2rem;
                    font-weight: 700;
                    letter-spacing: 3px;
                    margin-bottom: 20px;

                    @include g.mq-down(lg) {
                        padding-left: 0px;
                    }
                }

                .read {
                    padding-left: 85px;
                    color: #fff;

                    @include g.mq-down(lg) {
                        padding-left: 0px;
                    }
                }
            }
        }

        .slides {
            position: relative;
            top: -125px;
            margin-left: calc(50vw - 1100px / 2);

            @include g.mq-down(lg) {
                top: -60px;
                margin-left: 30px;
            }

            .slide {
                .item {
                    width: 330px;
                    margin-right: 66px;

                    @include g.mq-down(lg) {
                        width: g.spvw(295);
                        margin-right: g.spvw(30);
                    }

                    .photo {
                        width: 100%;
                        height: 330px;
                        margin-bottom: 15px;

                        @include g.mq-down(lg) {
                            height: g.spvw(295);
                        }

                        figure {
                            width: 100%;
                            height: 100%;
                            @include g.imgFit();
                        }
                    }

                    .caption {
                        .title {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 10px;

                            .num {
                                color: #DD8945;
                                width: 45px;
                                line-height: 1.1;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;

                                em {
                                    font-style: normal;
                                    display: block;
                                    font-size: 3rem;
                                    font-weight: 700;
                                    letter-spacing: 3.8px;
                                }

                                .ja {
                                    display: block;

                                    font-size: 1rem;
                                    letter-spacing: 2px;
                                }
                            }

                            .catch {
                                width: 80%;
                                font-weight: 700;
                                font-size: 1.5rem;
                                text-align: justify;
                            }
                        }

                        .read {
                            text-align: justify;
                            margin-bottom: 10px;
                        }
                    }
                }
            }

            .slick-prev {
                left: -70px;

                @include g.mq-down(lg) {
                    left: -20px;
                }
            }

            .slick-next {
                right: 70px;

                @include g.mq-down(lg) {
                    right: 10px;
                }
            }

            .slick-dots {
                @include g.mq-down(lg) {
                    text-align: left;
                }

            }
        }

    }

    .works-sec {
        margin-bottom: 80px;

        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background: g.$orange-thin;
            margin-left: calc(50vw - 1100px / 2);
            margin-bottom: 70px;

            @include g.mq-down(lg) {
                margin-left: 20px;
            }
        }

        .inner {
            padding-left: calc(50vw - 1100px / 2 + 20px);

            @include g.mq-down(lg) {
                padding: 0 30px;
            }
        }

        .list {
            width: 100%;
            max-width: 1100px;
            margin: 40px 0 0;
            display: flex;
            justify-content: space-between;

            @include g.mq-down(lg) {
                display: block;
            }

            .item {
                width: 31%;
                position: relative;

                @include g.mq-down(lg) {
                    width: 100%;
                    margin-bottom: 60px;
                }

                .photo {
                    margin-bottom: 20px;
                }

                .caption {
                    .catch {
                        font-size: 1.4rem;
                        font-weight: 700;
                        letter-spacing: 2px;
                        margin-bottom: 10px;


                    }

                    .read {
                        line-height: 2;
                        margin-bottom: 20px;
                        font-weight: 700;
                    }

                    .btn {
                        justify-content: flex-start;
                    }
                }
            }
        }
    }

    .interview-sec {
        margin-bottom: 125px;

        @include g.mq-down(lg) {
            margin-bottom: 80px;
            padding-bottom: 0;
        }

        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background: g.$orange-thin;
            margin-left: calc(50vw - 1100px / 2);
            margin-bottom: 70px;

            @include g.mq-down(lg) {
                margin-left: 20px;
            }
        }

        .inner {
            padding-left: calc(50vw - 1100px / 2);
            width: 100%;

            @include g.mq-down(lg) {
                position: relative;
                padding-left: 30px;
                padding-bottom: 75px;
            }

            .title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 50px;
                position: relative;

                @include g.mq-down(lg) {
                    display: block;
                    position: static;
                }

                .btnArea {
                    max-width: 150px;
                    width: 20%;


                    @include g.mq-down(lg) {
                        position: absolute;
                        bottom: 0px;
                        left: 30px;
                        width: auto;

                    }
                }
            }

            .slides {
                .slide {
                    .item {
                        width: 330px;
                        margin-right: 66px;

                        @include g.mq-down(lg) {
                            width: g.spvw(295);
                            margin-right: g.spvw(30);
                        }

                        .photo {
                            width: 100%;
                            height: 458px;

                            @include g.mq-down(lg) {
                                height: g.spvw(411);
                            }

                            figure {
                                width: 100%;
                                height: 100%;
                                @include g.imgFit();
                            }
                        }

                        .caption {
                            .ja {
                                font-size: 1.4rem;
                                font-weight: 700;
                                margin-bottom: 10px;
                            }

                            .category {
                                li {
                                    display: inline-block;
                                    margin-right: 5px;
                                    color: #DD8945;
                                    border: solid 1px #EB7227;
                                    border-radius: 15px;
                                    padding: 3px 10px;
                                    font-size: 1.2rem;
                                }
                            }
                        }
                    }
                }

                .slick-prev {
                    left: -70px;

                    @include g.mq-down(lg) {
                        left: -20px;
                    }
                }

                .slick-next {
                    right: 70px;

                    @include g.mq-down(lg) {
                        right: 10px;
                    }
                }

            }
        }
    }

    .recruitment-sec {
        padding: 80px 0 120px;
        background: #F4F2F0;
        position: relative;

        @include g.mq-down(lg) {
            padding: 60px 0 160px;
        }

        .inner {
            @include g.mq-down(lg) {
                width: calc(100% - 60px);
            }
        }

        .title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 50px;

            @include g.mq-down(lg) {
                display: block;
                margin-bottom: 30px;
            }

            .btnArea {
                max-width: 150px;
                width: 20%;

                @include g.mq-down(lg) {
                    width: auto;
                    position: absolute;
                    bottom: 80px;
                    left: 30px;
                }
            }
        }

    }

    .faq-sec {
        padding: 100px 0 145px;

        @include g.mq-down(lg) {
            padding: 70px 0 100px;
        }

        .inner {
            width: 100%;
            padding-left: calc(50vw - 1100px / 2);

            @include g.mq-down(lg) {
                padding: 0;
            }

            .title {
                margin-bottom: 30px;

                @include g.mq-down(lg) {
                    padding: 0 30px;
                }
            }

            .faq_list {
                width: 100%;
                margin-left: 50px;

                @include g.mq-down(lg) {
                    margin-left: 0;
                }

                .list {
                    position: relative;
                    border-bottom: solid 1px #E0BFAB;

                    dt,
                    dd {
                        max-width: 1000px;
                        width: calc(100% - 60px);

                        .btn {
                            .el_btn2 {
                                justify-content: space-between;


                                .txt {
                                    @include g.mq-down(lg) {
                                        border-bottom: none;
                                    }
                                }

                                .deco {
                                    &::before {
                                        transform: rotate(135deg);
                                        transition: transform .2s;
                                    }

                                    @include g.mq-down(lg) {
                                        width: 30px;
                                        height: 30px;

                                        &::after {
                                            width: 30px;
                                            height: 30px;
                                        }
                                    }
                                }
                            }
                        }

                        .txt {
                            display: flex;

                            span {
                                width: 40px;
                                font-size: 1.5rem;
                                font-weight: 700;

                                @include g.mq-down(lg) {
                                    font-size: 2.5rem;
                                }
                            }

                            p {
                                font-weight: 500;
                                width: calc(100% - 40px);
                            }
                        }
                    }

                    dt {
                        cursor: pointer;

                        &.is-open {
                            .btn {
                                .el_btn2 {

                                    .deco {
                                        &::before {
                                            transform: rotate(-45deg);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    dd {
                        padding-right: 60px;

                        @include g.mq-down(lg) {
                            padding-right: 40px;
                        }
                    }

                    .q_item {
                        padding: 25px 0;

                        @include g.mq-down(lg) {
                            padding: 25px 30px;
                        }
                    }

                    .a_item {
                        margin-bottom: 25px;
                        display: none;

                    }

                    .el_btn3 {
                        display: block;
                        cursor: pointer;
                        width: 40px;
                        height: 40px;
                        position: absolute;
                        right: 50px;
                        top: 50%;
                        transform: translateY(-50%);

                        @include g.mq-down(lg) {
                            right: 0;
                            top: 40px;
                        }

                        &.open {
                            .deco {
                                &::before {
                                    -webkit-transform: rotate(-45deg);
                                    transform: rotate(-45deg);
                                }
                            }
                        }

                        .deco {
                            display: inline-block;
                            width: 40px;
                            height: 40px;
                            background: #fff;
                            border-radius: 50%;
                            position: relative;
                            border: 1px solid #efefef;
                            position: absolute;

                            @include g.mq-down(lg) {}



                            &::after {
                                content: "";
                                display: block;
                                width: 4px;
                                height: 4px;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                background-color: g.$text-color;
                                border-radius: 50%;
                                transition: width .2s, height .2s, background-color .2s;

                                @include g.mq-down(lg) {
                                    width: 40px;
                                    height: 40px;
                                    background-color: g.$orange;
                                }
                            }

                            &::before {
                                content: "";
                                opacity: 0;
                                display: block;
                                width: 4px;
                                height: 4px;
                                border-top: 1px solid #fff;
                                border-right: 1px solid #fff;
                                -webkit-transform: rotate(135deg);
                                transform: rotate(135deg);
                                position: absolute;
                                left: calc(50% - 2px);
                                top: calc(50% - 2px);
                                z-index: 2;
                                transition: opacity .2s;

                                @include g.mq-down(lg) {
                                    opacity: 1;
                                }
                            }
                        }



                        &:hover {
                            @include g.mq-up(lg) {
                                .txt {
                                    border-bottom: 1px solid #EF7D20;
                                }


                                .deco {
                                    &::before {
                                        opacity: 1;
                                    }

                                    &::after {
                                        width: 40px;
                                        height: 40px;
                                        background-color: g.$orange;
                                    }
                                }
                            }

                            .deco {
                                &::before {
                                    opacity: 1;
                                }

                                &::after {
                                    width: 40px;
                                    height: 40px;
                                    background-color: g.$orange;
                                }
                            }
                        }

                        &.--rev {
                            flex-direction: row-reverse;

                            .txt {
                                margin-right: 0;
                                margin-left: 15px;

                                @include g.mq-down(lg) {
                                    margin-left: 10px;
                                }
                            }

                            .deco {
                                transform: rotate(-180deg);
                            }
                        }
                    }

                }
            }
        }
    }

    footer {
        .inquiry-sec {
            background: linear-gradient(180deg, #fff 0%, #fff calc(50% - 50px), g.$gray calc(50% - 50px), g.$gray 100%);
        }
    }
}
