@use "../global" as g;

.p-service-category {
  .mv-sec {
    margin-bottom: 90px;

    @include g.mq-down(lg) {
      margin-bottom: 60px;
    }

    .inner {
      padding: 0 33px;

      @include g.mq-down(lg) {
        padding: 0;
      }
    }
  }

  .outline-sec {
    margin-bottom: 110px;

    @include g.mq-down(lg) {
      margin-bottom: 60px;
    }

    .inner {
      @include g.mq-down(lg) {
        width: calc(100% - 60px);
        margin: 0 auto;
      }

      .catch {
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 20px;

        @include g.mq-down(lg) {
          font-size: 1.8rem;
        }
      }

      .read {
        font-weight: 500;
        line-height: 2;
      }
    }
  }

  .content-sec {
    margin-bottom: 120px;

    @include g.mq-down(lg) {
      margin-bottom: 80px;
    }

    .inner {
      .list {
        padding-left: calc(50vw - 1366px / 2);

        @include g.mq-down(lg) {
          padding-left: 0;
        }

        .item {
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 4px;

          @include g.mq-down(lg) {
            display: block;
            margin-bottom: 40px;
          }

          &:nth-last-of-type(1) {
            margin-bottom: 0;
          }

          .photo {
            width: 603px;
            height: 341px;

            @include g.mq-down(lg) {
              height: g.spvw(212);
              margin-bottom: 30px;
            }

            figure {
              width: 100%;
              height: 100%;
              @include g.imgFit();
            }
          }

          .text {
            width: calc(50vw + 80px);
            padding-left: 80px;

            @include g.mq-down(lg) {
              width: 100%;
              padding-left: 20px;
            }

            .midashi {
              font-weight: 700;
              padding-bottom: 15px;
              margin-bottom: 25px;
              border-bottom: 1px solid g.$orange-thin;

              .en {
                font-size: 2rem;
                letter-spacing: 1px;
                color: g.$orange;

              }

              .ja {
                font-size: 1.6rem;
              }
            }

            .read {
              width: 100%;
              max-width: 500px;
              line-height: 2;
              font-weight: 500;
              margin-bottom: 20px;

              @include g.mq-down(lg) {
                padding-right: 30px;
              }
            }

            .btn {
              max-width: 145px;

              .txt {
                padding-bottom: 3px;
                border-bottom: 2px solid g.$orange;
              }

              .deco {
                @include g.mq-down(lg) {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .otherservice-sec {
    padding: 70px 0 115px;
    background: g.$gray;

    @include g.mq-down(lg) {
      padding: 60px 0 100px;
    }

    .midashi {
      margin-bottom: 35px;

      .en {
        display: block;
        text-align: center;
        font-weight: 700;
        font-size: 2rem;
        letter-spacing: 1px;
      }

      .ja {
        display: block;
        font-weight: 500;
        text-align: center;
      }
    }

    .list {
      display: flex;

      @include g.mq-down(lg) {
        flex-wrap: wrap;
      }

      .item {
        width: 20%;
        height: 273px;

        @include g.mq-down(lg) {
          width: 50%;
          height: g.spvw(188);
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          position: relative;
          background: #000;

          &:hover {
            figure {
              opacity: 0.6;
            }
          }

          figure {
            width: 100%;
            height: 100%;
            opacity: 0.8;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            transition: opacity .2s;
            @include g.imgFit();
          }

          .text {
            position: relative;
            z-index: 1;
            color: #fff;

            .ja {
              display: block;
              font-weight: 700;
              text-align: center;
              letter-spacing: 1px;
            }

            .en {
              display: block;
              text-align: center;
              font-size: 1.2rem;
              letter-spacing: 2px;
            }
          }
        }
      }
    }
  }
}
