@use "../global" as g;

.el_btn1 {
  display: flex;
  align-items: center;

  @include g.mq-down(lg) {
    justify-content: space-between;
  }

  .txt {
    display: inline-block;
    margin-right: 13px;
  }

  .deco {
    display: inline-block;
    width: 18px;
    height: 18px;
    background: #fff;
    border-radius: 50%;
    position: relative;

    @include g.mq-down(lg) {
      width: 23px;
      height: 23px;
    }

    &::after {
      content: "";
      display: block;
      width: 4px;
      height: 4px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: g.$orange;
      border-radius: 50%;
      transition: width .2s, height .2s;
    }

    &::before {
      content: "";
      opacity: 0;
      display: block;
      width: 4px;
      height: 4px;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      left: calc(50% - 2px);
      top: calc(50% - 2px);
      z-index: 2;
      transition: opacity .2s;
    }
  }

  &:hover {
    .deco {
      &::before {
        opacity: 1;
      }

      &::after {
        width: 18px;
        height: 18px;

        @include g.mq-down(lg) {
          width: 23px;
          height: 23px;
        }

      }
    }
  }
}

.el_btn2 {
  display: flex;
  align-items: center;

  @include g.mq-down(lg) {
    justify-content: space-between;
  }

  .txt {
    display: inline-block;
    margin-right: 15px;
    font-weight: 700;
    letter-spacing: 1px;

    @include g.mq-down(lg) {
      margin-right: 10px;
      border-bottom: 2px solid g.$orange;
    }
  }

  .deco {
    display: inline-block;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
    position: relative;
    border: 1px solid #efefef;



    &::after {
      content: "";
      display: block;
      width: 4px;
      height: 4px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: g.$text-color;
      border-radius: 50%;
      transition: width .2s, height .2s, background-color .2s;

      @include g.mq-down(lg) {
        width: 40px;
        height: 40px;
        background-color: g.$orange;
      }
    }

    &::before {
      content: "";
      opacity: 0;
      display: block;
      width: 4px;
      height: 4px;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      left: calc(50% - 2px);
      top: calc(50% - 2px);
      z-index: 2;
      transition: opacity .2s;

      @include g.mq-down(lg) {
        opacity: 1;
      }
    }
  }

  &:hover {
    @include g.mq-up(lg) {
      .deco {
        &::before {
          opacity: 1;
        }

        &::after {
          width: 40px;
          height: 40px;
          background-color: g.$orange;
        }
      }
    }

    .deco {
      &::before {
        opacity: 1;
      }

      &::after {
        width: 40px;
        height: 40px;
        background-color: g.$orange;
      }
    }
  }

  &.--rev {
    flex-direction: row-reverse;

    .txt {
      margin-right: 0;
      margin-left: 15px;

      @include g.mq-down(lg) {
        margin-left: 10px;
      }
    }

    .deco {
      transform: rotate(-180deg);
    }
  }
}

.el_btn3 {
  .deco {
    display: inline-block;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
    position: relative;
    border: 1px solid #efefef;

    &::after {
      content: "";
      display: block;
      width: 4px;
      height: 4px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: g.$text-color;
      border-radius: 50%;
      transition: width .2s, height .2s, background-color .2s;

      @include g.mq-down(lg) {
        width: 40px;
        height: 40px;
        background-color: g.$orange;
      }
    }

    &::before {
      content: "";
      opacity: 0;
      display: block;
      width: 4px;
      height: 4px;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      left: calc(50% - 2px);
      top: calc(50% - 2px);
      z-index: 2;
      transition: opacity .2s;

      @include g.mq-down(lg) {
        opacity: 1;
      }
    }
  }

  &:hover {
    @include g.mq-up(lg) {
      .deco {
        &::before {
          opacity: 1;
        }

        &::after {
          width: 40px;
          height: 40px;
          background-color: g.$orange;
        }
      }
    }

  }
}

.el_btn4 {
  figure {
    position: relative;

    .deco {
      position: absolute;
      bottom: 15px;
      right: 15px;
    }
  }

  .deco {
    display: inline-block;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
    position: relative;
    border: 1px solid #efefef;

    &::after {
      content: "";
      display: block;
      width: 4px;
      height: 4px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: g.$text-color;
      border-radius: 50%;
      transition: width .2s, height .2s, background-color .2s;

      @include g.mq-down(lg) {
        width: 40px;
        height: 40px;
        background-color: g.$orange;
      }
    }

    &::before {
      content: "";
      opacity: 0;
      display: block;
      width: 4px;
      height: 4px;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      left: calc(50% - 2px);
      top: calc(50% - 2px);
      z-index: 2;
      transition: opacity .2s;

      @include g.mq-down(lg) {
        opacity: 1;
      }
    }
  }

  &:hover {
    @include g.mq-up(lg) {
      .deco {
        &::before {
          opacity: 1;
        }

        &::after {
          width: 40px;
          height: 40px;
          background-color: g.$orange;
        }
      }
    }

  }
}

.el_extbtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 5px;

  .icon {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-image: url(../images/common/icon_externallink-01.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.el_accbtn {
  display: flex;
  align-items: center;

  @include g.mq-down(lg) {
    justify-content: space-between;
  }

  .txt {
    display: inline-block;
    margin-right: 13px;
  }

  .deco {
    display: inline-block;
    width: 18px;
    height: 18px;
    background: #fff;
    border-radius: 50%;
    position: relative;
    background-image: url(../images/common/icon_acc-01.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: transform .2s;

    @include g.mq-down(lg) {
      width: 23px;
      height: 23px;
    }
  }

  &.is_active {
    .deco {
      transform: rotate(-180deg);
    }
  }
}

.menu-btn {
  width: 80px;
  height: 80px;
  padding: 22px 25.5px 24px;

  .menu-trigger,
  .menu-trigger span {
    display: inline-block;
    box-sizing: border-box;
    transition: all .2s;
  }

  .menu-trigger {
    width: 100%;
    height: 20px;
    position: relative;
  }

  .menu-trigger span {
    background-color: g.$text-color;
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
  }

  //.menu-trigger.active span {
  //    background-color: #FFFFFF;
  //}
  .menu-trigger span:nth-of-type(1) {
    top: 0;
    transform: translateY(0) rotate(0);
  }

  .menu-trigger span:nth-of-type(2) {
    top: 9px;
  }

  .menu-trigger span:nth-of-type(3) {
    bottom: 0;
    transform: translateY(0) rotate(0);
  }

  /* Animation*/
  .menu-trigger.active span:nth-of-type(1) {
    top: 50%;
    transform: rotate(45deg);
  }

  .menu-trigger.active span:nth-of-type(2) {
    transform: scaleX(0);
  }

  .menu-trigger.active span:nth-of-type(3) {
    top: 50%;
    transform: rotate(-45deg);
  }
}

.bl_title1 {
  width: 100%;
  height: 586px;
  position: relative;

  @include g.mq-down(lg) {
    height: g.spvw(450);
  }

  figure {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    @include g.imgFit();
  }

  .title {
    position: absolute;
    top: calc(50% - 40px);
    left: 147px;
    z-index: 1;
    color: #fff;

    @include g.mq-down(lg) {
      left: 30px;
    }

    .part {
      font-size: 2rem;
      font-weight: 200;
      margin-bottom: 5px;
      letter-spacing: 1px;
    }

    .en {
      font-size: 4rem;
      font-weight: 700;
      letter-spacing: 2px;
      line-height: 5rem;

      @include g.mq-down(lg) {
        line-height: 4rem;
        margin-bottom: 5px;
      }
    }

    .ja {
      font-size: 2rem;

      @include g.mq-down(lg) {
        font-size: 1.5rem;
      }
    }

    .catch {
      font-size: 4.6rem;
      font-weight: 700;
      letter-spacing: 8px;

      @include g.mq-down(lg) {
        font-size: 3rem;
        letter-spacing: 3px;
      }
    }

    .fieldtype {
      display: flex;
      color: #fff;
      margin-top: 20px;

      @include g.mq-down(lg) {
        display: block;
        margin-top: 10px;
      }

      dt {
        font-size: 1.5rem;
        margin-right: 15px;

        @include g.mq-down(lg) {
          margin-right: 15px;
          margin-bottom: 10px;
        }
      }

      dd {
        span {
          display: inline-block;
          border: 1px solid #fff;
          border-radius: 30px;
          padding: 2px 10px;
          margin-right: 4px;
          letter-spacing: 1px;

          @include g.mq-down(lg) {
            margin-bottom: 4px;
          }
        }
      }
    }
  }
}

.bl_title2 {
  width: 100%;

  h1 {
    .en {
      font-size: 4rem;
      font-weight: 700;
      letter-spacing: 2px;

      @include g.mq-down(lg) {
        font-size: 3rem;
      }
    }

    .ja {
      font-size: 1.5rem;
    }
  }
}

.news-card {

  .title {
    font-weight: 700;
    margin-bottom: 10px;
  }

  .data {
    display: flex;

    .cat {
      margin-bottom: 5px;
      margin-right: 15px;

      @include g.mq-down(lg) {
        font-size: 1.2rem;
      }

      span {
        display: inline-block;
        color: g.$orange;
        border: 1px solid g.$orange;
        padding: 1px 10px;
        border-radius: 50px;
        margin: 0 4px 4px 0;
        font-size: 1.2rem;
      }
    }

    .pub-date {
      color: g.$orange;
    }
  }

  .sp-more {
    display: inline-block;
    font-weight: 700;
    border-bottom: 2px solid g.$orange;

    @include g.mq-up(lg) {
      display: none;
    }
  }

}
