@use "../global" as g;

.p-case-single {
  .mv-sec {
    margin-bottom: 80px;

    @include g.mq-down(lg) {
      margin-bottom: 50px;
    }

    .inner {
      padding: 0 33px;

      @include g.mq-down(lg) {
        padding: 0;
      }
    }
  }

  .data-sec {
    margin-bottom: 60px;

    @include g.mq-down(lg) {
      margin-bottom: 40px;
    }

    .inner {
      @include g.mq-down(lg) {
        width: calc(100% - 60px);
      }

      .title {
        font-size: 3rem;
        letter-spacing: 1px;
        font-weight: 700;
        margin-bottom: 10px;

        @include g.mq-down(lg) {
          font-size: 2.6rem;
        }
      }

      .cat {
        margin-bottom: 5px;

        span {
          display: inline-block;
          color: g.$orange;
          border: 1px solid g.$orange;
          padding: 3px 10px;
          border-radius: 50px;
          margin: 0 4px 4px 0;
        }
      }

      .tag {
        margin-bottom: 20px;

        span {

          color: g.$orange;

          &::before {
            content: "# ";
          }

          &::after {
            content: " | ";
            display: inline-block;
            padding: 0 5px;
          }

          &:last-child {
            &::after {
              content: "";
            }
          }
        }
      }
    }
  }

  .content-sec {
    margin-bottom: 120px;

    .inner {
      @include g.mq-down(lg) {
        width: calc(100% - 60px);
      }
    }

    .block {

      .photo {
        margin-bottom: 30px;

        @include g.mq-down(lg) {
          margin-left: -30px;
          width: calc(100% + 60px);
        }

        figure {
          margin-bottom: 6px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .caption {

        .midashi {
          font-size: 1.8rem;
          font-weight: 700;
          margin-bottom: 15px;
        }

        .read {
          font-weight: 700;
          margin-bottom: 10px;
        }

        .btn {
          display: inline-block;
        }
      }

    }

    .back {
      margin-top: 90px;

      .btn {
        a {
          justify-content: center;
        }

      }
    }
  }

  footer {
    .inquiry-sec {
      padding-top: 100px;

      @include g.mq-down(lg) {
        padding-top: 60px;
      }
    }
  }
}
