@use "../global" as g;


.insertphoto-sec {
  padding-left: calc(50vw - 1006px / 2);
  margin-bottom: 80px;

  @include g.mq-down(lg) {
    padding-left: 0;
    margin-bottom: 70px;
  }

  figure {
    width: 100%;
    max-width: 1150px;
    height: 517px;
    @include g.imgFit();

    @include g.mq-down(lg) {
      height: g.spvw(375);
    }

  }
}

.post-sec {
  margin-bottom: 80px;

  .title {
    font-size: 2.2rem;
    font-weight: 700;
    padding-bottom: 20px;
    margin-bottom: 30px;
    margin-left: calc(50vw - 1006px / 2);
    margin-top: 60px;
    border-bottom: 1px solid g.$orange-thin;
    box-sizing: content-box;

    @include g.mq-down(lg) {
      margin-left: 30px;
      font-size: 2rem;
    }
  }

  .text-block {
    width: calc(100% - 60px);
    max-width: 900px;
    padding-left: calc(50vw - 800px / 2);
    box-sizing: content-box;
    margin-bottom: 40px;

    @include g.mq-down(lg) {
      padding-left: 0;
      margin: 0 auto;
    }

    .catch {
      font-size: 1.8rem;
      font-weight: 700;
      margin-bottom: 20px;
    }

    .read {
      font-weight: 700;
      margin-bottom: 20px;
      line-height: 2;
    }

    .photo {
      margin-bottom: 20px;
    }

    .btn {
      display: inline-block;
      margin-bottom: 20px;
    }

  }

  .text-block2 {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 60px);
    max-width: 900px;
    padding-left: calc(50vw - 800px / 2);
    box-sizing: content-box;
    margin-bottom: 40px;

    @include g.mq-down(lg) {
      display: block;
      margin: 0 auto;
    }

    .photo {
      width: 240px;
      height: 240px;

      @include g.mq-down(lg) {
        width: 100%;
        height: g.spvw(315);
        margin-bottom: 20px;
      }

      figure {
        width: 100%;
        height: 100%;
        @include g.imgFit();
      }
    }

    .text {
      width: calc(100% - 290px);

      @include g.mq-down(lg) {
        width: 100%;
      }

      .catch {
        font-size: 1.6rem;
        font-weight: 700;
        margin-bottom: 10px;
      }

      .read {
        font-weight: 700;
        line-height: 2;
        margin-bottom: 10px;
      }

      .btn {
        display: inline-block;
      }
    }
  }

  .gallery-block {
    width: calc(100% - 60px);
    max-width: 900px;
    padding-left: calc(50vw - 800px / 2);
    box-sizing: content-box;
    margin-bottom: 40px;

    @include g.mq-down(lg) {
      margin: 0 auto;
    }

    .list {
      display: flex;
      flex-wrap: wrap;
      gap: percentage(33px / 900px);

      @include g.mq-down(lg) {
        gap: 15px;
      }

      li {
        width: percentage(200px / 900px);
        margin-bottom: 20px;

        @include g.mq-down(lg) {
          width: calc(50% - 7.5px);
          margin-bottom: 5px;
        }

        a {
          display: block;
          @include g.image-link_zoom();
        }

        figure {
          width: 100%;
          height: g.pcvw(200);
          max-height: 200px;
          @include g.imgFit();

          @include g.mq-down(lg) {
            height: g.spvw(150);
          }
        }

        .caption {
          font-weight: 700;
          text-align: center;
        }
      }
    }
  }
}

.links-sec {
  padding: 60px 0 80px;

  .inner {
    text-align: center;

    .btn {
      display: inline-block;
    }
  }
}
