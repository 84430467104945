@use "../global" as g;

.p-top {
  #js-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
  }

  .op_wrap {
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;

    .op-text {
      display: none;
      text-align: center;
      font-size: g.pcvw(37);
      font-weight: 700;
      color: g.$orange;

      @include g.mq-down(lg) {
        font-size: g.spvw(27);

        span {
          display: inline-block;
          width: g.spvw(14);
        }
      }

    }
  }

  .mv-sec {
    width: 100%;
    height: calc(100vh - 92.8px);
    background-color: #fff;
    position: relative;
    z-index: 1;

    @include g.mq-down(lg) {
      height: calc(100vh - 80px);
      overflow: hidden;
    }

    .loop-slide {
      display: flex;
      width: 100vw;
      height: 100%;
      overflow: hidden;

      img {
        display: block;
        width: auto;
        height: 100%;

        &:first-child {
          animation: loopslide 200s -100s linear infinite;
        }

        &:last-child {
          animation: loopslide2 200s linear infinite;
        }
      }

      @keyframes loopslide {
        0% {
          transform: translateX(100%);
        }

        to {
          transform: translateX(-100%);
        }
      }

      @keyframes loopslide2 {
        0% {
          transform: translateX(0);
        }

        to {
          transform: translateX(-200%);
        }
      }
    }

    .mask {
      width: 100%;
      height: calc(100% + 2px);
      position: absolute;
      top: -1px;
      left: 0;
      z-index: 1;

      figure {
        width: 100vw;
        height: 100vh;
        margin-bottom: 0;
        @include g.imgFit();

        @include g.mq-down(lg) {
          width: 100%;
          height: auto;
          align-items: flex-end;

          &::before {
            content: "";
            width: 100%;
            height: 16vh;
            background: #fff;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
          }

          &::after {
            content: "";
            width: 100%;
            height: 16vh;
            background: #fff;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: -1;
          }
        }

        img {
          object-position: left top;
        }
      }
    }
  }

  .about-sec {
    background-color: #fff;
    padding-left: calc(50vw - 1100px / 2);
    padding-bottom: 93px;
    position: relative;
    z-index: 3;

    @include g.mq-down(lg) {
      padding: 0 30px;
    }

    .inner {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-direction: row-reverse;

      @include g.mq-down(lg) {
        display: block;
      }

      .photo {
        width: g.pcvw(599);
        height: 900px;
        position: relative;

        @include g.mq-down(lg) {
          width: 100%;
          height: auto;
          margin-left: 30px;
        }

        figure {
          width: 100%;
          height: 100%;
          @include g.imgFit();
          overflow: hidden;
          ;

          img {
            object-position: 50% 100%;
          }

        }

        .deco {
          position: absolute;
          bottom: 300px;
          left: 0;
          font-size: 7rem;
          font-weight: 700;
          letter-spacing: 6px;
          color: g.$gray;
          transform: rotate(90deg);
          transform-origin: left center;

          @include g.mq-down(lg) {
            font-size: 5rem;
            bottom: 200px;
          }
        }
      }

      .text {
        width: 50%;
        max-width: 472px;
        margin-bottom: 60px;

        @include g.mq-down(lg) {
          width: 100%;
        }

        .midashi {
          margin-bottom: 20px;

          .en {
            font-size: 5rem;
            font-weight: 700;
            line-height: 1.2;
            letter-spacing: 2px;
            margin-bottom: 15px;

            @include g.mq-down(lg) {
              font-size: 4rem;
              letter-spacing: 1px;
            }

            span {
              font-weight: 200;
              font-size: 4rem;

              @include g.mq-down(lg) {
                font-size: 3rem;
              }
            }
          }

          .ja {
            font-size: 2.2rem;
            font-weight: 700;
            letter-spacing: 2px;

            @include g.mq-down(lg) {
              font-size: 2rem;
            }
          }

        }

        .read {
          font-size: 1.5rem;
          line-height: 2;
          font-weight: 700;
          letter-spacing: 1.5px;
          margin-bottom: 20px;

          @include g.mq-down(lg) {
            font-size: 1.4rem;
          }
        }

        .btn {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }

  .ceremony-sec {
    .ceremony-nav {
      position: fixed;
      top: calc(100% - 240px);
      left: calc(100% - 120px);
      width: 40px;
      height: 180px;

      //margin-bottom: 0px;
      @include g.mq-down(lg) {
        width: 30px;
        height: 144px;
        top: calc(100% - 180px);
        left: calc(100% - 45px);
      }

      ul {
        margin-bottom: 30px;

        @include g.mq-down(lg) {
          margin-bottom: 10px;
        }

        li {
          text-align: center;

          span {
            display: inline-block;
            width: 8px;
            height: 8px;
            border: 1px solid #000;
            border-radius: 50%;
            cursor: pointer;
          }

          &.is-active {
            span {
              border: 1px solid g.$orange;
              background: g.$orange;
            }
          }
        }
      }
    }

    .triggers {
      .trigger {
        height: 100vh;

        // &--1 {
        //   background-color: rgba(#000000, 0.1);
        // }

        // &--2 {
        //   background-color: rgba(#ff0000, 0.2);
        // }

        // &--3 {
        //   background-color: rgba(#000000, 0.3);
        // }

        // &--4 {
        //   background-color: rgba(#ff0000, 0.4);
        // }

        // &--5 {
        //   background-color: rgba(#000000, 0.5);
        // }
        &--5 {
          height: 200vh;
        }


      }
    }

    #wrapper {
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      z-index: -1;
    }

    .list {
      width: 100%;
      position: relative;

      .item {
        width: 100%;
        height: 100vh;
        padding: 92.8px 40px 50px;
        background: #fff;
        position: absolute;
        top: 20px;
        left: 0;
        opacity: 0;
        transition: opacity .5s, top .5s;

        @include g.mq-down(lg) {
          padding: 80px 10px 10px;
        }

        figure {
          width: 100%;
          height: 100%;
          margin-bottom: 0;
          @include g.imgFit();
        }

        .text {
          position: absolute;
          top: 50%;
          left: 110px;

          @include g.mq-down(lg) {
            left: 30px;
            transform: translateY(-50%);
          }

          .midashi {
            width: 100%;
            margin-bottom: 30px;

            @include g.mq-down(lg) {
              margin-bottom: 15px;
            }

            .en {
              font-size: 5rem;
              font-weight: 700;
              line-height: 1.6;
              margin-bottom: 30px;

              @include g.mq-down(lg) {
                font-size: 4rem;
                margin-bottom: 15px;
              }

              span {
                font-size: 4rem;
                font-weight: 200;
                display: block;
                line-height: 0.8;

                @include g.mq-down(lg) {
                  font-size: 3rem;
                  line-height: 1;
                }
              }
            }

            .ja {
              font-size: 2rem;
              font-weight: 700;
              letter-spacing: 2px;

              @include g.mq-down(lg) {
                font-size: 1.8rem;
                letter-spacing: 1px;
              }
            }
          }

          .catch {
            font-size: 4rem;
            font-weight: 700;

            @include g.mq-down(lg) {
              font-size: 3rem;
            }
          }

          .read {
            width: calc(50% - 110px);
            font-size: 1.5rem;
            font-weight: 700;
            line-height: 2;

            @include g.mq-down(lg) {
              width: calc(100% - 40px);
            }
          }
        }

        &.ceremony-item-1 {
          .text {
            top: calc(50% + 93px);
            transform: translateY(-50%);

            @include g.mq-down(lg) {
              top: 50%;
            }
          }
        }

        &.ceremony-item-2,
        &.ceremony-item-3,
        &.ceremony-item-4,
        &.ceremony-item-5 {
          .text {
            color: #fff;
          }
        }

        &.is-scroll {
          opacity: 1;
          top: 0;
        }
      }
    }
  }

  .ourservice-sec {
    padding-top: 100px;
    background-color: #fff;
    position: relative;
    z-index: 3;

    .inner {
      position: relative;
      width: calc(100% - 60px);

      .midashi {
        margin-bottom: 60px;

        @include g.mq-down(lg) {
          margin-bottom: 50px;
        }

        .en {
          font-size: 4rem;
          font-weight: 700;
          letter-spacing: 2px;

          span {
            font-weight: 200;

            @include g.mq-down(lg) {
              font-size: 3rem;
              position: relative;
              top: -2px;
            }
          }
        }

        .ja {
          letter-spacing: 2px;
          font-size: 1.8rem;
          font-weight: 700;
          margin-left: 77px;

          @include g.mq-down(lg) {
            margin-left: 0;
          }
        }
      }

      .btn {
        position: absolute;
        bottom: 0;
        right: 0;
      }

    }

    .list {
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 33.3333%;
        height: 250px;
        background-color: #000;

        @include g.mq-down(lg) {
          width: 50%;
          height: g.spvw(188);
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          position: relative;
          @include g.image-link_zoom();

          .text {
            text-align: center;
            color: #fff;
            position: relative;
            z-index: 1;

            .ja {
              font-size: 2rem;
              font-weight: 700;

              @include g.mq-down(lg) {
                font-size: 1.6rem;
                margin-bottom: 8px;
              }
            }

            .en {
              font-size: 1.4rem;
              letter-spacing: 2px;

              @include g.mq-down(lg) {
                font-size: 1.3rem;
                letter-spacing: 1px;
                line-height: 1.4;
              }
            }
          }

          figure {
            width: 100%;
            height: 100%;
            @include g.imgFit();
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            opacity: 0.8;
          }

        }
      }
    }

    .list+.btn {
      @include g.mq-down(lg) {
        margin-top: 20px;

        a {
          justify-content: center;
        }
      }

    }

  }

  .ourfield-sec {
    padding-top: 155px;
    background-color: #fff;
    position: relative;
    z-index: 3;

    @include g.mq-down(lg) {
      padding-top: 120px;
    }

    .inner {
      .midashi {
        margin-bottom: 60px;

        @include g.mq-down(lg) {
          margin-bottom: 50px;
        }

        .en {
          font-size: 4rem;
          font-weight: 700;
          letter-spacing: 2px;

          span {
            font-weight: 200;

            @include g.mq-down(lg) {
              font-size: 3rem;
              position: relative;
              top: -2px;
            }
          }
        }

        .ja {
          letter-spacing: 2px;
          font-size: 1.8rem;
          font-weight: 700;
          margin-left: 77px;

          @include g.mq-down(lg) {
            margin-left: 0;
          }
        }
      }
    }

    .list {
      .item {
        display: flex;
        align-items: center;
        margin-bottom: 2px;

        &:last-child {
          margin-bottom: 0;
        }

        .photo {
          width: g.pcvw(950);
          max-width: calc(50vw + 273px);
          height: g.pcvw(250);
          max-height: 400px;
          min-height: 250px;


          @include g.mq-down(lg) {
            width: g.spvw(190);
            height: g.spvw(155);
            min-height: g.spvw(155);
          }

          figure {
            width: 100%;
            height: 100%;
            @include g.imgFit();
          }
        }

        .text {
          margin-left: 50px;

          @include g.mq-down(lg) {
            margin-left: 25px;
          }

          .komidashi {
            margin-bottom: 20px;

            @include g.mq-down(lg) {
              margin-bottom: 10px;
            }

            .en {
              font-size: 3rem;
              font-weight: 700;
              letter-spacing: 3px;

              @include g.mq-down(lg) {
                font-size: 1.8rem;
                letter-spacing: 1.5px;
              }

              span {
                display: block;
                color: g.$orange;
                font-size: 2rem;
                font-weight: 200;
                line-height: 0.8;

                @include g.mq-down(lg) {
                  font-size: 1.6rem;
                  letter-spacing: 1px;
                  line-height: 1;
                }
              }
            }

            .ja {
              font-weight: 700;
              font-size: 1.6rem;
              letter-spacing: 1px;

              @include g.mq-down(lg) {
                font-size: 1.3rem;
              }
            }
          }

          .btn {
            .deco {
              @include g.mq-down(lg) {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .news-sec {
    background-color: #fff;
    padding: 234px 0 117px;
    position: relative;
    z-index: 3;

    @include g.mq-down(lg) {
      padding: 100px 0 130px;
    }

    .inner {
      padding-left: calc(50vw - 1100px / 2);

      @include g.mq-down(lg) {
        padding: 0;
      }

      .box {
        display: flex;
        justify-content: space-between;

        @include g.mq-down(lg) {
          display: block;
        }

        .midashi {
          width: 225px;

          @include g.mq-down(lg) {
            width: calc(100% - 60px);
            margin: 0 auto 40px;
          }

          .en {
            font-size: 3rem;
            font-weight: 700;
            letter-spacing: 3px;
            line-height: 1.1;
          }

          .ja {
            font-weight: 700;
          }
        }

        .content {
          width: calc(100% - 225px);

          @include g.mq-down(lg) {
            width: calc(100% - 40px);
            margin: 0 auto;

          }

          dl {
            display: flex;
            padding: 30px 0;
            border-top: 1px solid g.$gray;
            position: relative;

            &::after {
              content: "";
              width: 6px;
              height: 6px;
              border-top: 1px solid g.$text-color;
              border-right: 1px solid g.$text-color;
              transform: rotate(45deg);
              position: absolute;
              top: calc(50% - 3px);
              right: 0;
            }

            @include g.mq-down(lg) {
              display: block;
              padding: 30px 30px 30px 0;
            }

            &:last-child {
              border-bottom: 1px solid g.$gray;
            }

            dt {
              width: 123px;

              @include g.mq-down(lg) {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .esg-sec {
    background-color: #fff;
    position: relative;
    z-index: 3;

    &::after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      background-image: url(../images/top/webp/img_esg-01.webp);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 422px;
      height: 720px;

      @include g.mq-down(lg) {
        width: 226px;
        height: 387px;
        right: -85px;
        bottom: auto;
        top: -34px;
        z-index: 1;
      }
    }

    .inner {
      padding-top: 165px;
      padding-bottom: 122px;
      position: relative;
      overflow: hidden;
      text-align: center;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 115vw;
        height: 100vw;
        background: g.$gray;
        border-radius: 50%;
        z-index: 0;

        @include g.mq-down(lg) {
          width: 200vw;
          height: 200vw;
        }
      }


    }

    .midashi {
      text-align: center;
      margin-bottom: 20px;
      position: relative;
      z-index: 1;

      @include g.mq-down(lg) {
        text-align: left;
        padding: 0 30px;
      }

      .en {
        font-size: 4rem;
        letter-spacing: 3px;
        font-weight: 700;
        margin-bottom: 5px;



        span {
          font-weight: 200;

          @include g.mq-down(lg) {
            font-size: 3rem;
          }
        }
      }

      .ja {
        font-weight: 700;
        font-size: 2rem;
      }
    }

    .read {
      width: 460px;
      text-align: center;
      margin: 0 auto 30px;
      line-height: 2;
      position: relative;
      z-index: 1;

      @include g.mq-down(lg) {
        width: 100%;
        text-align: left;
        padding: 0 30px;
        position: relative;
        z-index: 1;
      }
    }

    .btn {
      display: inline-block;
      position: relative;
      z-index: 1;

      @include g.mq-down(lg) {
        display: block;
        padding-left: 30px;

        a {
          justify-content: flex-start;
        }
      }
    }
  }

  .recruit-sec {
    padding-bottom: 105px;
    background: g.$gray;
    position: relative;
    z-index: 3;

    @include g.mq-down(lg) {
      padding-bottom: 90px;
    }

    .inner {
      .box {
        display: flex;
        align-items: center;

        @include g.mq-down(lg) {
          display: block;
        }

        .photo {
          width: percentage(860px / 1366px);
          max-width: calc(50vw + 120px);
          height: g.pcvw(500);
          max-height: 800px;
          min-height: 500px;
          overflow: hidden;

          @include g.mq-down(lg) {
            width: 100%;
            max-width: 100%;
            min-height: auto;
            height: 100vw;
            margin-bottom: 50px;
          }

          figure {
            width: 100%;
            height: 100%;
            @include g.imgFit();
          }
        }

        .text {
          width: calc(100% - percentage(860px / 1366px));
          max-width: 430px;
          padding-left: 70px;

          @include g.mq-down(lg) {
            width: calc(100% - 60px);
            margin: 0 auto;
            padding: 0;
          }

          .midashi {
            margin-bottom: 20px;

            @include g.mq-down(lg) {
              margin-bottom: 25px;
            }

            .en {
              font-size: 5rem;
              letter-spacing: 2px;
              font-weight: 700;
              line-height: 1.2;
              margin-bottom: 10px;

              @include g.mq-down(lg) {
                font-size: 4rem;
              }

              span {
                display: block;
                color: g.$orange;
                letter-spacing: 1px;
                font-size: 3rem;
                font-weight: 200;

                @include g.mq-down(lg) {
                  font-size: 2.6rem;
                  letter-spacing: 0;
                }

              }
            }

            .ja {
              font-size: 2rem;
              letter-spacing: 2px;
              font-weight: 700;

              @include g.mq-down(lg) {
                font-size: 1.8rem;
                letter-spacing: 1px;
              }
            }
          }

          .read {
            font-weight: 700;
            line-height: 2;
            margin-bottom: 30px;
          }

          .btn {
            a {
              justify-content: flex-start;
            }
          }
        }
      }
    }
  }
}
